import React, { useEffect, useState, useRef } from 'react';
import { fetchSeries } from '../../../api/dataService';
import SeriesSummary from '../seriesSummary/SeriesSummary';
import './completedSeries.css';

const CompletedSeries = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const liveStatsSeries = await fetchSeries('CompletedSeries');
        if (isMounted.current) {
          setData(liveStatsSeries);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Completed Series!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  if (data && data.Series && data.Series.length > 0) {
    return (
      <div className="statsNguts__completedSeries section__padding" id="completedSeries">
        <div className="statsNguts__completedSeries_heading">
          <h1 className="gradient__text">Completed Series</h1>
        </div>
        <div className="statsNguts__completedSeries_container">
          <div className="statsNguts__completedSeries_container_content">
            {data.Series.map((series) => (
              <SeriesSummary
                key={series.seriesId}
                seriesId={series.seriesId}
                seriesShortName={series.seriesShortName}
                seriesFullName={series.seriesFullName}
                season={series.season}
                numberOfMatches={series.numberOfMatches}
                seriesAvatar={series.seriesAvatar}
                seriesStatus={series.status}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="statsNguts__completedSeries_empty" />
  );
};

export default CompletedSeries;
