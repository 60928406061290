import React from 'react';

const BestStrikeRate = ({ items }) => {
  const bestTwoStrikeRate = items.slice(0, 2);

  return (
    <div>
      <div className="statsNguts__matchAnalysis_categoryTitle">Best Strike Rate</div>
      {bestTwoStrikeRate.map((item, index) => (
        <div className="statsNguts__matchAnalysis_categoryItem" key={index}> <strong>{item.Name}</strong> - {item.StrikeRate} ({item.Balls} Balls)</div>
      ))}
    </div>
  );
};

export default BestStrikeRate;
