import React, { useState } from 'react';
import parse from 'html-react-parser';
import './playerOverview.css';

const PlayerOverview = ({ player }) => {
  const [isOverviewExpanded, setIsOverviewExpanded] = useState(true);
  const playerOverview = JSON.stringify(player.overview);

  return (
    <div className="statsNguts__player_overviewContainer">
      <button type="button" onClick={() => setIsOverviewExpanded(!isOverviewExpanded)} className="overviewToggle">
        Overview
      </button>
      {isOverviewExpanded && (
      <div className="playerOverviewContent">
        <h3>{parse(playerOverview)}</h3>
      </div>
      )}
    </div>
  );
};

export default PlayerOverview;
