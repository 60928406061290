import React from 'react';
import MostRunsStats from './MostRunsStats';
import MostWicketsStats from './MostWicketsStats';
import ScoreStats from './ScoreStats';
import MostFoursStats from './MostFoursStats';
import MostSixesStats from './MostSixesStats';
import BestStrikeRate from './BestStrikeRate';
import BestEconomyRate from './BestEconomyRate';

const StatCategory = ({ statsData }) => {
  const orderedCategories = [
    { component: ScoreStats, dataKey: 'Score', title: 'Score' },
    { component: MostRunsStats, dataKey: 'MostRuns', title: 'Most Runs' },
    { component: BestStrikeRate, dataKey: 'BestStrikeRate', title: 'Best Strike Rate' },
    { component: MostWicketsStats, dataKey: 'MostWickets', title: 'Most Wickets' },
    { component: BestEconomyRate, dataKey: 'BestEconomyRate', title: 'Best Economy Rate' },
    { component: MostSixesStats, dataKey: 'MostSixes', title: 'Most Sixes' },
    { component: MostFoursStats, dataKey: 'MostFours', title: 'Most Fours' },
  ];

  return (
    <div>
      {orderedCategories.map(({ component: CategoryComponent, dataKey, title }, index) => (
        <CategoryComponent key={index} items={statsData[dataKey]} title={title} />
      ))}
    </div>
  );
};

export default StatCategory;
