import fetchApi from './baseApi';

export const fetchSeries = (seriesType) => {
  const url = `/series/${seriesType}`;
  return fetchApi(url);
};

export const fetchMatches = (series, matchType) => {
  const url = `/matches/${series}/${matchType}`;
  return fetchApi(url);
};

export const fetchMatchStats = (series, matchNumber, matchId) => {
  const url = `/matchStats/${series}/${matchNumber}/${matchId}`;
  return fetchApi(url);
};

export const fetchPostMatchAnalysis = (series, matchNumber, matchId) => {
  const url = `/postMatchAnalysis/${series}/${matchNumber}/${matchId}`;
  return fetchApi(url);
};

export const fetchSeriesStats = (series) => {
  const url = `/seriesStats/${series}`;
  return fetchApi(url);
};
