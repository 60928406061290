import React, { useState, useEffect } from 'react';
import './fantasyQuestion.css';
import { Chart } from 'react-google-charts';

function FantasyQuestion({ question, answer, onAnswer, onNext, onPrevious, questionIndex, totalQuestions }) {
  const [selectedOption, setSelectedOption] = useState(answer);

  useEffect(() => {
    setSelectedOption(answer);
  }, [answer]);

  const handleAnswer = (option) => {
    setSelectedOption(option);
    onAnswer(option);
  };

  const handleNextQuestion = () => {
    onNext();
  };

  const handlePreviousQuestion = () => {
    onPrevious();
  };

  return (
    <div className="statsNguts__fantasyQuestion">
      <div className="statsNguts__fantasyQuestion_box">
        <h2 className="statsNguts__fantasyQuestion_title">{questionIndex + 1}. {question.question}</h2>
        <div className="statsNguts__fantasyQuestion_options_container" style={{ '--num-options': question.options.length }}>
          {question.options.sort().map((option) => (
            <div
              key={option}
              className={`statsNguts__fantasyQuestion_options_tab ${selectedOption === option ? 'selected' : ''}`}
              onClick={() => handleAnswer(option)}
            >
              {option}
            </div>
          ))}
        </div>
        <div className="navigationButtons">
          {questionIndex > 0 && (
            <button type="button" className="navigationButton prev" onClick={handlePreviousQuestion}>
              &larr; Previous
            </button>
          )}
          {questionIndex < totalQuestions - 1 && (
            <button type="button" className="navigationButton next" onClick={handleNextQuestion}>
              Next &rarr;
            </button>
          )}
        </div>
        <div className="statsNguts__fantasyQuestion_stats">
          <div className="statsNguts__fantasyQuestion_stats_content">
            <h2>Stats that matters</h2>
            <h3>{question.stats}</h3>
            <p>{question.statsDuration}</p>
          </div>
          <div className="statsNguts__fantasyQuestion_chartContainer">
            {question.statsCharts.map((chart) => (
              <div className="statsNguts__fantasyQuestion_chartContainer_chart">
                <Chart
                  chartType={chart.chartType}
                  data={chart.data}
                  options={chart.options}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FantasyQuestion;
