import React, { useState } from 'react';
import parse from 'html-react-parser';
import './groundOverview.css';

const GroundOverview = ({ groundStats }) => {
  const [isGroundOverviewExpanded, setIsGroundOverviewExpanded] = useState(true);
  const groundOverview = JSON.stringify(groundStats.overview);

  return (
    <div className="statsNguts__ground_overviewContainer">
      <button type="button" onClick={() => setIsGroundOverviewExpanded(!isGroundOverviewExpanded)} className="overviewToggle">
        Overview
      </button>
      {isGroundOverviewExpanded && (
      <div className="groundOverviewContent">
        <h3>{parse(groundOverview)}</h3>
      </div>
      )}
    </div>
  );
};

export default GroundOverview;
