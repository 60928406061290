import React, { useEffect, useState, useRef } from 'react';
import { fetchMatches } from '../../../../api/dataService';
import MatchSummary from '../matchSummary/MatchSummary';
import './publishedMatches.css';

const PublishedMatches = ({ seriesId }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const matches = await fetchMatches(seriesId, 'PublishedMatches');
        if (isMounted.current) {
          setData(matches);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Published Match List!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  if (data && data.Matches && data.Matches.length > 0) {
    return (
      <div className="statsNguts__publishedMatches section__padding" id="matchList">
        <div className="statsNguts__publishedMatches_heading">
          <h1 className="gradient__text">Live</h1>
        </div>
        <div className="statsNguts__publishedMatches_container">
          <div className="statsNguts__publishedMatches_container_content">
            {data.Matches.map((match) => (
              <MatchSummary
                key={match.matchId}
                matchId={match.matchId}
                seriesId={seriesId}
                seriesShortName={match.seriesShortName}
                season={match.season}
                matchNumber={match.matchNumber}
                avatar={match.matchAvatar}
                matchTeamAShortName={match.teamAShortName}
                matchTeamBShortName={match.teamBShortName}
                matchGround={match.matchGroundFullName}
                matchGroundCity={match.matchGroundCity}
                matchDate={match.matchDate}
                matchTime={match.matchTime}
                matchStatsStatus={match.matchStatsStatus}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="statsNguts__publishedMatches_empty" />
  );
};

export default PublishedMatches;
