/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState, useRef } from 'react';
import './postMatchAnalysis.css';
import StatCategory from './StatCategory';
import { fetchPostMatchAnalysis } from '../../../../api/dataService';

const PostMatchAnalysis = React.forwardRef(({ seriesId, matchInfo, matchNumber, matchId }, ref) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const matchStats = await fetchPostMatchAnalysis(seriesId, matchNumber, matchId);
        if (isMounted.current) {
          setData(matchStats);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, [matchNumber, matchId]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Post Match Analysis!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  const { postMatchAnalysis } = data;

  return (
    <div ref={ref}>
      <div className="statsNguts__matchAnalysis">
        <div className="statsNguts__matchAnalysis_summary">
          <div className="statsNguts__matchAnalysis_matchResult">{postMatchAnalysis.matchResult}</div>
          <div className="statsNguts__matchAnalysis_motm">Player of the Match - {postMatchAnalysis.motm}</div>
        </div>
        <div className="statsNguts__matchAnalysis_overallContainer">
          <div className="statsNguts__matchAnalysis_teamContainer">
            <div className="statsNguts__matchAnalysis_logoContainer">
              <img src={require(`../../../../assets/image/teams/${matchInfo.teamAAvatar}`)} alt="Team A Logo" className="statsNguts__matchAnalysis_teamLogo" />
            </div>
            <div className="statsNguts__matchAnalysis_teamStatsContainer">
              <StatCategory statsData={postMatchAnalysis.teamAStats} />
            </div>
          </div>

          <div className="statsNguts__matchAnalysis_teamContainer">
            <div className="statsNguts__matchAnalysis_logoContainer">
              <img src={require(`../../../../assets/image/teams/${matchInfo.teamBAvatar}`)} alt="Team B Logo" className="statsNguts__matchAnalysis_teamLogo" />
            </div>
            <div className="statsNguts__matchAnalysis_teamStatsContainer">
              <StatCategory statsData={postMatchAnalysis.teamBStats} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PostMatchAnalysis;
