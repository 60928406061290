import React from 'react';
import statsNgutsLogo from '../../../assets/image/logo.png';
import './footer.css';

const Footer = () => (
  <div className="statsNguts__footer section__padding">
    <div className="statsNguts__footer_links">
      <div className="statsNguts__footer_links_logo">
        <img src={statsNgutsLogo} alt="statsNguts_logo" />
        <p>Stats N Guts <br /> All Rights Reserved</p>
      </div>
      <div className="statsNguts__footer_links_div">
        <h4>Links</h4>
        <p><a href="/about">About</a></p>
        <p><a href="https://www.instagram.com/statsnguts">Instagram</a></p>
        <p><a href="https://twitter.com/statsnguts">Twitter</a></p>
      </div>
      <div className="statsNguts__footer_links_div">
        <h4>Company</h4>
        <p><a href="/termsandconditions">Terms & Conditions</a></p>
        <p><a href="/privacypolicy">Privacy Policy</a></p>
      </div>
      <div className="statsNguts__footer_links_div">
        <h4>Get in touch</h4>
        <p>Stats N Guts</p>
        <p>+91-7985004476</p>
        <p>connect@statsnguts.com</p>
      </div>
    </div>

    <div className="statsNguts__footer_copyright">
      <p>@2024 Amatres Technologies Private Limited. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
