import React, { useState } from 'react';
import './teamh2hMatchups.css';
import PlayerMatchup from '../playerMatchup/PlayerMatchup';
import { getPlayerMatchups } from '../utils/StatsUtil.jsx';

const Teamh2hMatchups = ({ playerStats }) => {
  const data = getPlayerMatchups(playerStats);

  const [isTeamh2hMatchupsExpanded, setIsTeamh2hMatchupsExpanded] = useState(true);

  return (
    <div className="statsNguts__teamh2h_matchups">
      <button type="button" onClick={() => setIsTeamh2hMatchupsExpanded(!isTeamh2hMatchupsExpanded)} className="statsNguts__teamh2h_matchups_toggle">
        Matchups
      </button>
      {isTeamh2hMatchupsExpanded && (
        <div className="statsNguts__teamh2h_matchupsContainer">
          {data.playerMatchups.map((matchup, index) => (
            <PlayerMatchup key={index} batter={matchup.batter} bowler={matchup.bowler} stats={matchup.stats} highlight={matchup.highlight} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Teamh2hMatchups;
