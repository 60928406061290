import React, { useState } from 'react';
import Player from '../player/Player';
import './playerStats.css';

const PlayerStats = React.forwardRef(({ matchInfo, playerStats }, ref) => {
  const [activeTab, setActiveTab] = useState('teamA');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [teamAExpandedSections, setTeamAExpandedSections] = useState({
    wicketKeepers: false,
    bowlers: false,
    allRounders: false,
    batters: false,
  });

  const [teamBExpandedSections, setTeamBExpandedSections] = useState({
    wicketKeepers: false,
    bowlers: false,
    allRounders: false,
    batters: false,
  });

  const toggleTeamASection = (section) => {
    setTeamAExpandedSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const toggleTeamBSection = (section) => {
    setTeamBExpandedSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  return (
    <div ref={ref}>
      <div className="statsNguts__playerStats" id="playerStats">
        <div className="statsNguts__playerStats_heading">
          <h1 className="gradient__text">Player Stats</h1>
          <h3>Statistics from player&apos;s last 10 T20Is Innings
          </h3>
        </div>
        <div className="statsNguts__playerStats_tabs">
          <button
            type="button"
            className={`statsNguts__playerStats_tabButton ${activeTab === 'teamA' ? 'active' : ''}`}
            onClick={() => handleTabChange('teamA')}
          >
            {matchInfo.teamAShortName}
          </button>
          <button
            type="button"
            className={`statsNguts__playerStats_tabButton ${activeTab === 'teamB' ? 'active' : ''}`}
            onClick={() => handleTabChange('teamB')}
          >
            {matchInfo.teamBShortName}
          </button>
        </div>

        <div className={`statsNguts__playerStats_teamSection ${activeTab === 'teamA' ? 'active' : ''}`}>
          <div onClick={() => toggleTeamASection('wicketKeepers')} style={{ cursor: 'pointer' }}>
            <h2>{matchInfo.teamAShortName} - Wicket Keepers</h2>
          </div>
          {teamAExpandedSections.wicketKeepers && (
          <div className="statsNguts__playerStats_teamSection_playersContainer">
            {playerStats.teamA_WicketKeepers.map((player, index) => (
              <Player key={index} player={player} avatar={player.avatar} ground={matchInfo.matchGroundFullName} team={matchInfo.teamAShortName} opposition={matchInfo.teamBShortName} matchInfo={matchInfo} type="wicketKeeper" />
            ))}
          </div>
          )}
        </div>

        <div className={`statsNguts__playerStats_teamSection ${activeTab === 'teamA' ? 'active' : ''}`}>
          <div onClick={() => toggleTeamASection('batters')} style={{ cursor: 'pointer' }}>
            <h2>{matchInfo.teamAShortName} - Batters</h2>
          </div>
          {teamAExpandedSections.batters && (
          <div className="statsNguts__playerStats_teamSection_playersContainer">
            {playerStats.teamA_Batters.map((player, index) => (
              <Player key={index} player={player} avatar={player.avatar} ground={matchInfo.matchGroundFullName} team={matchInfo.teamAShortName} opposition={matchInfo.teamBShortName} matchInfo={matchInfo} type="batter" />
            ))}
          </div>
          )}
        </div>

        <div className={`statsNguts__playerStats_teamSection ${activeTab === 'teamA' ? 'active' : ''}`}>
          <div onClick={() => toggleTeamASection('allRounders')} style={{ cursor: 'pointer' }}>
            <h2>{matchInfo.teamAShortName} - All Rounders</h2>
          </div>
          {teamAExpandedSections.allRounders && (
          <div className="statsNguts__playerStats_teamSection_playersContainer">
            {playerStats.teamA_AllRounders.map((player, index) => (
              <Player key={index} player={player} avatar={player.avatar} ground={matchInfo.matchGroundFullName} team={matchInfo.teamAShortName} opposition={matchInfo.teamBShortName} matchInfo={matchInfo} type="allrounder" />
            ))}
          </div>
          )}
        </div>

        <div className={`statsNguts__playerStats_teamSection ${activeTab === 'teamA' ? 'active' : ''}`}>
          <div onClick={() => toggleTeamASection('bowlers')} style={{ cursor: 'pointer' }}>
            <h2>{matchInfo.teamAShortName} - Bowlers</h2>
          </div>
          {teamAExpandedSections.bowlers && (
          <div className="statsNguts__playerStats_teamSection_playersContainer">
            {playerStats.teamA_Bowlers.map((player, index) => (
              <Player key={index} player={player} avatar={player.avatar} ground={matchInfo.matchGroundFullName} team={matchInfo.teamAShortName} opposition={matchInfo.teamBShortName} matchInfo={matchInfo} type="bowler" />
            ))}
          </div>
          )}
        </div>

        <div className={`statsNguts__playerStats_teamSection ${activeTab === 'teamB' ? 'active' : ''}`}>
          <div onClick={() => toggleTeamBSection('wicketKeepers')} style={{ cursor: 'pointer' }}>
            <h2>{matchInfo.teamBShortName} - Wicket Keepers</h2>
          </div>
          {teamBExpandedSections.wicketKeepers && (
          <div className="statsNguts__playerStats_teamSection_playersContainer">
            {playerStats.teamB_WicketKeepers.map((player, index) => (
              <Player key={index} player={player} avatar={player.avatar} ground={matchInfo.matchGroundFullName} team={matchInfo.teamBShortName} opposition={matchInfo.teamAShortName} matchInfo={matchInfo} type="wicketKeeper" />
            ))}
          </div>
          )}
        </div>

        <div className={`statsNguts__playerStats_teamSection ${activeTab === 'teamB' ? 'active' : ''}`}>
          <div onClick={() => toggleTeamBSection('batters')} style={{ cursor: 'pointer' }}>
            <h2>{matchInfo.teamBShortName} - Batters</h2>
          </div>
          {teamBExpandedSections.batters && (
          <div className="statsNguts__playerStats_teamSection_playersContainer">
            {playerStats.teamB_Batters.map((player, index) => (
              <Player key={index} player={player} avatar={player.avatar} ground={matchInfo.matchGroundFullName} team={matchInfo.teamBShortName} opposition={matchInfo.teamAShortName} matchInfo={matchInfo} type="batter" />
            ))}
          </div>
          )}
        </div>

        <div className={`statsNguts__playerStats_teamSection ${activeTab === 'teamB' ? 'active' : ''}`}>
          <div onClick={() => toggleTeamBSection('allRounders')} style={{ cursor: 'pointer' }}>
            <h2>{matchInfo.teamBShortName} - All Rounders</h2>
          </div>
          {teamBExpandedSections.allRounders && (
          <div className="statsNguts__playerStats_teamSection_playersContainer">
            {playerStats.teamB_AllRounders.map((player, index) => (
              <Player key={index} player={player} avatar={player.avatar} ground={matchInfo.matchGroundFullName} team={matchInfo.teamBShortName} opposition={matchInfo.teamAShortName} matchInfo={matchInfo} type="allrounder" />
            ))}
          </div>
          )}
        </div>

        <div className={`statsNguts__playerStats_teamSection ${activeTab === 'teamB' ? 'active' : ''}`}>
          <div onClick={() => toggleTeamBSection('bowlers')} style={{ cursor: 'pointer' }}>
            <h2>{matchInfo.teamBShortName} - Bowlers</h2>
          </div>
          {teamBExpandedSections.bowlers && (
          <div className="statsNguts__playerStats_teamSection_playersContainer">
            {playerStats.teamB_Bowlers.map((player, index) => (
              <Player key={index} player={player} avatar={player.avatar} ground={matchInfo.matchGroundFullName} team={matchInfo.teamBShortName} opposition={matchInfo.teamAShortName} matchInfo={matchInfo} type="bowler" />
            ))}
          </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default PlayerStats;
