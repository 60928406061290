import React from 'react';
import './privacyPolicy.css';

const PrivacyPolicy = () => (
  <div className="statsNguts__privacyPolicy" id="privacyPolicy">
    <div className="statsNguts__privacyPolicy_heading">
      <h1 className="gradient__text">Privacy Policy</h1>
      <h2><strong>Introduction</strong></h2>
      <br />
      <p>At StatsNGuts.com (the &apos;Website&apos;), operated by Amatres Technologies Private Limited (&apos;Company&apos;, &apos;we&apos;, &apos;us&apos;, or &apos;our&apos;), we are committed to protecting your privacy. </p>
      <br />
      <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from StatsNGuts.com (the &apos;Website&apos;).</p>
      <br />
      <h2><strong>Personal Information We Collect</strong></h2>
      <br />
      <p>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”</p>
      <br />
      <p>We collect Device Information using the following technologies:</p>
      <br />
      <ul>
        <h3>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>.</h3>
        <h3>“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</h3>
        <h3>“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.</h3>
      </ul>
      <br />
      <p>Additionally, when you subscribe to receive stats over email, we collect your email address. We refer to this information as “Subscription Information.”</p>
      <br />
      <p>When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Subscription Information.</p>
      <br />
      <h2><strong>How Do We Use Your Personal Information?</strong></h2>
      <p>We use the Subscription Information that we collect to provide you with the stats over email as per your subscription request.</p>
      <p>We use the Device Information that we collect to help us improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p>
      <br />
      <h2><strong>Sharing Your Personal Information</strong></h2>
      <p>We do not share your personal information with any third parties.</p>
      <br />
      <h2><strong>Data Retention</strong></h2>
      <p>When you provide us with your email address for subscription, we will maintain your Subscription Information until you unsubscribe from our email list.</p>
      <br />
      <h2><strong>Changes</strong></h2>
      <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
      <br />
      <h2><strong>Contact Us</strong></h2>
      <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at connect@statsnguts.com or call us at +91-7985004476</p>
      <br />
      <p>This policy is effective as of 1 January 2024.</p>

    </div>
  </div>
);

export default PrivacyPolicy;
