/* eslint-disable no-param-reassign */
import { getTopRunScorers, getTopWicketTakers } from './fantasyTeamsByCombinationsStatsUtil.jsx';

export const GetFantasyTeams = (answers, playerStats, matchInfo) => {
  let teamAfantasyPlayerList = [];
  let teamAPlayingWicketKeepers = [];
  let teamAPlayingBatters = [];
  let teamAPlayingAllRounders = [];
  let teamAPlayingBowlers = [];
  let teamBfantasyPlayerList = [];
  let teamBPlayingWicketKeepers = [];
  let teamBPlayingBatters = [];
  let teamBPlayingAllRounders = [];
  let teamBPlayingBowlers = [];

  teamAfantasyPlayerList = matchInfo.teamAPlayingSquad;
  teamBfantasyPlayerList = matchInfo.teamBPlayingSquad;

  const teamAWicketkeepers = playerStats.teamA_WicketKeepers.filter((player) => player.status === 'Available');
  const teamABatters = playerStats.teamA_Batters.filter((player) => player.status === 'Available');
  const teamAAllRounders = playerStats.teamA_AllRounders.filter((player) => player.status === 'Available');
  const teamABowlers = playerStats.teamA_Bowlers.filter((player) => player.status === 'Available');
  const teamBWicketkeepers = playerStats.teamB_WicketKeepers.filter((player) => player.status === 'Available');
  const teamBBatters = playerStats.teamB_Batters.filter((player) => player.status === 'Available');
  const teamBAllRounders = playerStats.teamB_AllRounders.filter((player) => player.status === 'Available');
  const teamBBowlers = playerStats.teamB_Bowlers.filter((player) => player.status === 'Available');

  if (teamAfantasyPlayerList.length > 0) {
    teamAWicketkeepers.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingWicketKeepers.push(player);
      }
    });

    teamABatters.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingBatters.push(player);
      }
    });

    teamAAllRounders.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingAllRounders.push(player);
      }
    });

    teamABowlers.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingBowlers.push(player);
      }
    });
  } else {
    teamAPlayingWicketKeepers = teamAWicketkeepers;
    teamAPlayingBatters = teamABatters;
    teamAPlayingAllRounders = teamAAllRounders;
    teamAPlayingBowlers = teamABowlers;
  }

  if (teamBfantasyPlayerList.length > 0) {
    teamBWicketkeepers.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingWicketKeepers.push(player);
      }
    });

    teamBBatters.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingBatters.push(player);
      }
    });

    teamBAllRounders.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingAllRounders.push(player);
      }
    });

    teamBBowlers.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingBowlers.push(player);
      }
    });
  } else {
    teamBPlayingWicketKeepers = teamBWicketkeepers;
    teamBPlayingBatters = teamBBatters;
    teamBPlayingAllRounders = teamBAllRounders;
    teamBPlayingBowlers = teamBBowlers;
  }

  const a1 = parseInt(answers[0], 10);
  const a2 = parseInt(answers[1], 10);
  const a3 = parseInt(answers[2], 10);
  const a4 = parseInt(answers[3], 10);
  const a5 = parseInt(answers[4], 10);

  const highestRunScorer = getTopRunScorers(teamAPlayingWicketKeepers.concat(teamBPlayingWicketKeepers).concat(teamAPlayingBatters).concat(teamBPlayingBatters), 1);
  const highestWicketTaker = getTopWicketTakers(teamAPlayingBowlers.concat(teamBPlayingBowlers), 'All', 1);
  const team1Captain = highestRunScorer[0].Name;
  const team1ViceCaptain = highestWicketTaker[0].Name;

  const team2Captain = highestWicketTaker[0].Name;
  const team2ViceCaptain = highestRunScorer[0].Name;

  const fantasyAllRounderBatterNumber = Math.ceil(a3 / 2);
  const fantasyAllRounderBowlerNumber = a3 - fantasyAllRounderBatterNumber;
  const fantasyWicketKeepers = getTopRunScorers(teamAPlayingWicketKeepers.concat(teamBPlayingWicketKeepers), a1);
  const fantasyBatters = getTopRunScorers(teamAPlayingBatters.concat(teamBPlayingBatters), a2);
  const fantasyAllRounderBatters = getTopRunScorers(teamAPlayingAllRounders.concat(teamBPlayingAllRounders), fantasyAllRounderBatterNumber);
  fantasyAllRounderBatters.forEach((player) => {
    const ar1 = teamAPlayingAllRounders.find((entry) => entry.name === player.Name);
    if (ar1) {
      teamAPlayingAllRounders = teamAPlayingAllRounders.filter((item) => item.name !== player.Name);
    }
    const ar2 = teamBPlayingAllRounders.find((entry) => entry.name === player.Name);
    if (ar2) {
      teamBPlayingAllRounders = teamBPlayingAllRounders.filter((item) => item.name !== player.Name);
    }
  });
  const fantasyAllRounderBowlers = getTopWicketTakers(teamAPlayingAllRounders.concat(teamBPlayingAllRounders), 'All', fantasyAllRounderBowlerNumber);
  const fantasyBowlers = getTopWicketTakers(teamAPlayingBowlers.concat(teamBPlayingBowlers), 'All', a4);
  const fantasyAllRounders = fantasyAllRounderBatters.concat(fantasyAllRounderBowlers);

  const numberOfTeamsAnswer = a5;

  const fantasyTeams = [];
  for (let i = 0; i < numberOfTeamsAnswer; i += 1) {
    const teamNumber = i + 1;
    const teamName = `Team ${teamNumber}`;
    const players = [];
    fantasyWicketKeepers.forEach((wicketKeeper) => {
      let element = playerStats.teamA_WicketKeepers.find((entry) => entry.name === wicketKeeper.Name);
      if (element) {
        const player = {
          name: element.name,
          role: 'WicketKeeper',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }

      element = playerStats.teamB_WicketKeepers.find((entry) => entry.name === wicketKeeper.Name);
      if (element) {
        const player = {
          name: element.name,
          role: 'WicketKeeper',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }
    });

    fantasyBatters.forEach((batter) => {
      let element = playerStats.teamA_Batters.find((entry) => entry.name === batter.Name);
      if (element) {
        const player = {
          name: element.name,
          role: 'Batter',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }

      element = playerStats.teamB_Batters.find((entry) => entry.name === batter.Name);
      if (element) {
        const player = {
          name: element.name,
          role: 'Batter',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }
    });

    fantasyAllRounders.forEach((allRounder) => {
      let element = playerStats.teamA_AllRounders.find((entry) => entry.name === allRounder.Name);
      if (element) {
        const player = {
          name: element.name,
          role: 'AllRounder',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }

      element = playerStats.teamB_AllRounders.find((entry) => entry.name === allRounder.Name);
      if (element) {
        const player = {
          name: element.name,
          role: 'AllRounder',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }
    });

    fantasyBowlers.forEach((bowler) => {
      let element = playerStats.teamA_Bowlers.find((entry) => entry.name === bowler.Name);
      if (element) {
        const player = {
          name: element.name,
          role: 'Bowler',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }

      element = playerStats.teamB_Bowlers.find((entry) => entry.name === bowler.Name);
      if (element) {
        const player = {
          name: element.name,
          role: 'Bowler',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }
    });

    const fantasyTeam = {
      teamName,
      players,
    };

    fantasyTeams.push(fantasyTeam);
  }

  if (numberOfTeamsAnswer === 1) {
    const fantasyTeam1 = fantasyTeams[0];
    const fantasyTeam1Players = fantasyTeam1.players;
    fantasyTeam1Players.forEach((player) => {
      if (player.name === team1Captain) {
        player.isCaptain = true;
      }
      if (player.name === team1ViceCaptain) {
        player.isViceCaptain = true;
      }
    });
  }

  if (numberOfTeamsAnswer === 2) {
    const fantasyTeam1 = fantasyTeams[0];
    const fantasyTeam1Players = fantasyTeam1.players;
    fantasyTeam1Players.forEach((player) => {
      if (player.name === team1Captain) {
        player.isCaptain = true;
      }
      if (player.name === team1ViceCaptain) {
        player.isViceCaptain = true;
      }
    });

    const fantasyTeam2 = fantasyTeams[1];
    const fantasyTeam2Players = fantasyTeam2.players;
    fantasyTeam2Players.forEach((player) => {
      if (player.name === team2Captain) {
        player.isCaptain = true;
      }
      if (player.name === team2ViceCaptain) {
        player.isViceCaptain = true;
      }
    });
  }

  return fantasyTeams;
};
