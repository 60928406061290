import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthenticator, Authenticator, View, useTheme, Heading, Button } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import './login.css';
import awsconfig from './awsExports';

// Configure Amplify
Amplify.configure({
  ...awsconfig,
  Auth: {
    // Your existing Auth configuration
    region: awsconfig.aws_cognito_region,
    userPoolId: awsconfig.aws_user_pools_id,
    userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
    // Explicitly use localStorage
    storage: window.localStorage,
  },
});

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large} />
    );
  },

  Footer() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large} />
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();
      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();
      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },

  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
    },
  },

  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
    },
  },

  ForgotPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
    },
  },

  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
    },
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your Email',
      isRequired: true,
      label: 'Email',
    },
  },
  signUp: {
    username: {
      order: 1,
      placeholder: 'Enter your Email',
      isRequired: true,
      label: 'Email',
    },
    name: {
      order: 2,
      isRequired: true,
      label: 'Name',
    },
    password: {
      order: 3,
      isRequired: true,
      label: 'Password',
    },
    confirm_password: {
      order: 4,
      isRequired: true,
      label: 'Confirm Password',
    },
  },
  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password',
      isRequired: true,
      label: 'Password',
    },
  },
  forgotPassword: {
    username: {
      placeholder: 'Enter your email',
      isRequired: true,
      label: 'Email',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your Confirmation Code',
      label: 'Confirmation Code',
      isRequired: true,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please',
      label: 'Confirm Password',
      isRequired: true,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'Confirmation Code',
      placeholder: 'Enter your Confirmation Code',
      isRequired: true,
    },
  },
};

const signUpAttributes = ['name'];

const Login = () => {
  const { user } = useAuthenticator();
  const location = useLocation();
  const [redirectPath, setRedirectPath] = useState('/');

  useEffect(() => {
    if (location.state?.from) {
      setRedirectPath(location.state.from.pathname);
    }
  }, [location.state]);

  if (user) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <Authenticator formFields={formFields} signUpAttributes={signUpAttributes} components={components}>
      {({ signOut }) => <button type="button" onClick={signOut}>Sign out</button>}
    </Authenticator>
  );
};

export default Login;
