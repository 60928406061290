/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import './fantasyTeamsByCombinations.css';
import Brand from '../../../common/brand/Brand.jsx';
import { GetFantasyTeams } from './fantasyTeamsByCombinationsUtil.jsx';

function FantasyTeamsByCombinations({ questions, answers, playerStats, restartQuestionnaire, matchInfo }) {
  const fantasyTeams = GetFantasyTeams(answers, playerStats, matchInfo);

  return (
    <div>
      <div className="fantasyTeamsByCombinations__questions_and_answers_table">
        <h2>Your Selection</h2>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            {questions.map((question, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{question.question}</td>
                <td>{answers[index]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="statsNguts__fantasyTeamsByCombinations">
        <h2>Your Fantasy Teams<br />
          <h5 className="statsNguts__fantasyTeamsByCombinations_Disclaimer">
            *Teams are based on tentative playing XI of both the teams. Actual playing XI will be decided after toss on Match day
          </h5>
        </h2>
        <div className="statsNguts__fantasyTeamsByCombinations_container">
          {fantasyTeams.map((team, teamIndex) => (
            <div key={teamIndex} className="statsNguts__fantasyTeamsByCombinations_container_team">
              <h3 className="statsNguts__fantasyTeamsByCombinations_container_team_name">{team.teamName}</h3>
              {['WicketKeeper', 'Batter', 'AllRounder', 'Bowler'].map((role) => (
                <div key={role} className="statsNguts__fantasyTeamsByCombinations_container_team_section">
                  <h3>{role}s</h3>
                  <div className="statsNguts__fantasyTeamsByCombinations_container_players">
                    {team.players.filter((player) => player.role === role).map((player, index) => (
                      <div key={index} className="statsNguts__fantasyTeamsByCombinations_container_player_item">
                        <img src={require(`../../../../assets/image/players/${player.avatar}`)} alt={player.name} className="statsNguts__fantasyTeamsByCombinations_container_player_avatar" />
                        <div className="statsNguts__fantasyTeamsByCombinations_container_player_info">
                          <p className="statsNguts__fantasyTeamsByCombinations_container_player_name">{player.name}</p>
                          {player.isCaptain && <span className="statsNguts__fantasyTeamsByCombinations_container_player_role_captain">(C)</span>}
                          {player.isViceCaptain && <span className="statsNguts__fantasyTeamsByCombinations_container_player_role_vice-captain">(VC)</span>}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <button type="button" className="statsNguts__fantasyTeamsByCombinations_button" onClick={restartQuestionnaire}>Generate Again</button>
      </div>
      <Brand />
    </div>
  );
}

export default FantasyTeamsByCombinations;
