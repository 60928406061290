/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga4';
import './matchStats.css';
import PlayerStats from '../playerStats/PlayerStats';
import GroundStats from '../../common/groundStats/GroundStats';
import TeamStats from '../../common/teamStats/TeamStats';
import MatchNews from '../../common/matchNews/MatchNews';
import FantasyQuestionnaire from '../../common/fantasyQuestionnaire/FantasyQuestionnaire.jsx';
import FantasyQuestionnaireByCombinations from '../../common/fantasyQuestionnaireByCombinations/FantasyQuestionnaireByCombinations.jsx';
import FanatsyTeamPrediction from '../../common/fanatsyTeamPrediction/FanatsyTeamPrediction.jsx';
import PostMatchAnalysis from '../../common/postMatchAnalysis/PostMatchAnalysis.jsx';
import { fetchMatchStats } from '../../../../api/dataService';

const MatchStats = ({ seriesId, matchNumber, matchId }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);
  const [activeComponent, setActiveComponent] = useState('playerStats');
  const playerStatsRef = useRef(null);
  const groundStatsRef = useRef(null);
  const teamStatsRef = useRef(null);
  const matchNewsRef = useRef(null);
  const fantasyQuestionnaireRef = useRef(null);
  const fantasyQuestionnaireByCombinationsRef = useRef(null);
  const fantasyTeamPredictionRef = useRef(null);
  const postMatchAnalysisRef = useRef(null);

  const getRefForActiveComponent = (component) => {
    switch (component) {
      case 'playerStats':
        return playerStatsRef;
      case 'groundStats':
        return groundStatsRef;
      case 'teamStats':
        return teamStatsRef;
      case 'matchNews':
        return matchNewsRef;
      case 'postMatchAnalysis':
        return postMatchAnalysisRef;
      case 'fantasyQuestionnaire':
        return fantasyQuestionnaireRef;
      case 'fantasyQuestionnaireByCombinations':
        return fantasyQuestionnaireByCombinationsRef;
      case 'fantasyTeamBestPossibleByStats':
        return fantasyTeamPredictionRef;
      default:
        return null;
    }
  };

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const matches = await fetchMatchStats(seriesId, matchNumber, matchId);
        if (isMounted.current) {
          setData(matches);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, [matchNumber, matchId]);

  useEffect(() => {
    const ref = getRefForActiveComponent(activeComponent);
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeComponent]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Awesome Match Stats!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  const { matchInfo } = data;
  const { groundStats } = data;
  const { teamStats } = data;
  const { news } = data;
  const { playerStats } = data;

  ReactGA.send({ hitType: 'pageview', page: '/matchstats', title: `MatchStats Page - Match ${matchInfo.matchNumber}` });

  const handleButtonClick = (component) => {
    ReactGA.event({
      category: 'User Actions',
      action: `${component}-Click`,
      label: 'MatchStats',
    });

    let ref;
    switch (component) {
      case 'playerStats':
        ref = playerStatsRef;
        break;
      case 'groundStats':
        ref = groundStatsRef;
        break;
      case 'teamStats':
        ref = teamStatsRef;
        break;
      case 'matchNews':
        ref = matchNewsRef;
        break;
      case 'postMatchAnalysis':
        ref = postMatchAnalysisRef;
        break;
      case 'fantasyQuestionnaire':
        ref = fantasyQuestionnaireRef;
        break;
      case 'fantasyQuestionnaireByCombinations':
        ref = fantasyQuestionnaireByCombinationsRef;
        break;
      case 'fantasyTeamBestPossibleByStats':
        ref = fantasyTeamPredictionRef;
        break;
      default:
        return;
    }

    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    setActiveComponent(component);
  };

  const showFanatsyButtons = matchInfo.matchStatsStatus === 'Published';
  const showPostMatchAnalysisButtons = matchInfo.matchStatsStatus === 'Completed' || matchInfo.matchStatsStatus === 'Live';

  return (
    <div className="statsNguts__matchStats section__padding" id="matchStats">
      <div className="statsNguts__matchStats_info">
        <div className="statsNguts__matchStats_avatarContainer">
          <img className="statsNguts__matchStats_avatarContainer_avatar" src={require(`../../../../assets/image/teams/${matchInfo.matchAvatar}`)} alt="matchAvatar" />
        </div>
        <div className="statsNguts__matchStats_headingContainer">
          <div className="statsNguts__matchStats_headingBox">
            <h1 className="gradient__text">Match { matchInfo.matchNumber } - Stats & News</h1>
            <h2>{matchInfo.teamAFullName} Vs {matchInfo.teamBFullName}</h2>
            <h3>{matchInfo.matchGroundFullName}, {matchInfo.matchGroundCity}</h3>
            <h4>{matchInfo.matchDate}, {matchInfo.matchTime}</h4>
          </div>
        </div>
      </div>

      <div className="statsNguts__matchStats_buttons">
        <div className="statsNguts__matchStats_buttons_stats_buttons">
          <button type="button" onClick={() => handleButtonClick('playerStats')}>Player Stats</button>
          <button type="button" onClick={() => handleButtonClick('groundStats')}>Ground Stats</button>
          <button type="button" onClick={() => handleButtonClick('teamStats')}>Team Stats</button>
          <button type="button" onClick={() => handleButtonClick('matchNews')}>Match News</button>
        </div>
        {showFanatsyButtons && (
        <div className="statsNguts__matchStats_buttons_bestPlayingEleven_buttons">
          <button type="button" onClick={() => handleButtonClick('fantasyTeamBestPossibleByStats')}>Fantasy Team - Prediction</button>
        </div>
        )}
        {showFanatsyButtons && (
        <div className="statsNguts__matchStats_buttons_generateFantasy_buttons">
          <button type="button" onClick={() => handleButtonClick('fantasyQuestionnaire')}>Generate Your Own Fantasy Teams <br />
            <h5>By using your gut feeling</h5>
          </button>
          <button type="button" onClick={() => handleButtonClick('fantasyQuestionnaireByCombinations')}>Generate Your Own Fantasy Teams<br />
            <h5>By selecting player combinations</h5>
          </button>
        </div>
        )}
        {showPostMatchAnalysisButtons && (
        <div className="statsNguts__matchStats_buttons_postMatchAnalysisButton">
          <button type="button" onClick={() => handleButtonClick('postMatchAnalysis')}>Post Match Analysis</button>
        </div>
        )}
      </div>

      {activeComponent === 'playerStats' && <PlayerStats ref={playerStatsRef} matchInfo={matchInfo} playerStats={playerStats} />}
      {activeComponent === 'groundStats' && <GroundStats ref={groundStatsRef} matchInfo={matchInfo} groundStats={groundStats} />}
      {activeComponent === 'teamStats' && <TeamStats ref={teamStatsRef} matchInfo={matchInfo} teamStats={teamStats} playerStats={playerStats} />}
      {activeComponent === 'matchNews' && <MatchNews ref={matchNewsRef} matchNews={news} />}
      {activeComponent === 'fantasyQuestionnaire' && <FantasyQuestionnaire ref={fantasyQuestionnaireRef} matchInfo={matchInfo} playerStats={playerStats} groundStats={groundStats} teamStats={teamStats} />}
      {activeComponent === 'fantasyQuestionnaireByCombinations' && <FantasyQuestionnaireByCombinations ref={fantasyQuestionnaireByCombinationsRef} matchInfo={matchInfo} playerStats={playerStats} />}
      {activeComponent === 'fantasyTeamBestPossibleByStats' && <FanatsyTeamPrediction ref={fantasyTeamPredictionRef} matchInfo={matchInfo} playerStats={playerStats} />}
      {activeComponent === 'postMatchAnalysis' && <PostMatchAnalysis ref={postMatchAnalysisRef} seriesId={seriesId} matchInfo={matchInfo} matchNumber={matchNumber} matchId={matchId} />}
    </div>
  );
};

export default MatchStats;
