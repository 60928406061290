import React from 'react';
import { dreamEleven, mpl, myElevenCircle } from './imports';
import './brand.css';

const Brand = () => (
  <div className="statsNguts__brand section__padding">
    <div className="statsNguts__brand_heading">
      <h1 className="gradient__text">Try your Luck!</h1>
      <h3>Join a contest on any of the platforms below</h3>
    </div>
    <div className="statsNguts__brand_image_container">
      <div className="brand_image">
        <a href="https://www.dream11.com" target="_blank" rel="noopener noreferrer">
          <img src={dreamEleven} alt="Dream11" />
        </a>
      </div>
      <div className="brand_image">
        <a href="https://www.mpl.live" target="_blank" rel="noopener noreferrer">
          <img src={mpl} alt="MPL" />
        </a>
      </div>
      <div className="brand_image">
        <a href="https://www.my11circle.com" target="_blank" rel="noopener noreferrer">
          <img src={myElevenCircle} alt="My11Circle" />
        </a>
      </div>
    </div>
  </div>
);

export default Brand;
