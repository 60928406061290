import React from 'react';

const ScoreStats = ({ items }) => (
  <div>
    <div className="statsNguts__matchAnalysis_categoryTitle">Score</div>
    {items.map((item, index) => (
      <div className="statsNguts__matchAnalysis_categoryItem" key={index}> <strong>{item.Score}</strong></div>
    ))}
  </div>
);

export default ScoreStats;
