import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import './playerCharts.css';
import {
  getLastFiveRunsScoredChart,
  getStrikeRateAndBallsFacedChart,
  getLastFiveWicketsTakenChart,
  getEconomyRateAndOversBowledChart,
  getLastFiveRunsScoredAcrossFormatsChart,
  getStrikeRateAndBallsFacedAcrossFormatsChart,
  getLastFiveWicketsTakenAcrossFormatsChart,
  getEconomyRateAndOversBowledAcrossFormatsChart,
} from '../../common/utils/ChartsUtil.jsx';

const PlayerCharts = ({ player, type }) => {
  const [isChartExpanded, setIsChartExpanded] = useState(true);
  const charts = [];
  switch (type) {
    case 'wicketKeeper':
    case 'batter':
      if (player.runsScored.length > 0) {
        charts.push(getLastFiveRunsScoredChart(player.runsScored));
        charts.push(getStrikeRateAndBallsFacedChart(player.runsScored));
      }
      if (player.internationalT20RunsScored.length > 0 || player.domesticT20RunsScored.length > 0) {
        charts.push(getLastFiveRunsScoredAcrossFormatsChart(player.runsScored, player.internationalT20RunsScored, player.domesticT20RunsScored, player.internationalT20LeagueRunsScored));
        charts.push(getStrikeRateAndBallsFacedAcrossFormatsChart(player.runsScored, player.internationalT20RunsScored, player.domesticT20RunsScored, player.internationalT20LeagueRunsScored));
      }
      break;
    case 'allrounder':
      if (player.runsScored.length > 0) {
        charts.push(getLastFiveRunsScoredChart(player.runsScored));
        charts.push(getStrikeRateAndBallsFacedChart(player.runsScored));
      }
      if (player.internationalT20RunsScored.length > 0 || player.domesticT20RunsScored.length > 0 || player.internationalT20LeagueRunsScored.length > 0) {
        charts.push(getLastFiveRunsScoredAcrossFormatsChart(player.runsScored, player.internationalT20RunsScored, player.domesticT20RunsScored, player.internationalT20LeagueRunsScored));
        charts.push(getStrikeRateAndBallsFacedAcrossFormatsChart(player.runsScored, player.internationalT20RunsScored, player.domesticT20RunsScored, player.internationalT20LeagueRunsScored));
      }
      if (player.wicketsTaken.length > 0) {
        charts.push(getLastFiveWicketsTakenChart(player.wicketsTaken));
        charts.push(getEconomyRateAndOversBowledChart(player.wicketsTaken));
      }
      if (player.internationalT20WicketsTaken.length > 0 || player.domesticT20WicketsTaken.length > 0 || player.internationalT20LeagueWicketsTaken.length > 0) {
        charts.push(getLastFiveWicketsTakenAcrossFormatsChart(player.wicketsTaken, player.internationalT20WicketsTaken, player.domesticT20WicketsTaken, player.internationalT20LeagueWicketsTaken));
        charts.push(getEconomyRateAndOversBowledAcrossFormatsChart(player.wicketsTaken, player.internationalT20WicketsTaken, player.domesticT20WicketsTaken, player.internationalT20LeagueWicketsTaken));
      }
      break;
    case 'bowler':
      if (player.wicketsTaken.length > 0) {
        charts.push(getLastFiveWicketsTakenChart(player.wicketsTaken));
        charts.push(getEconomyRateAndOversBowledChart(player.wicketsTaken));
      }
      if (player.internationalT20WicketsTaken.length > 0 || player.domesticT20WicketsTaken.length > 0 || player.internationalT20LeagueWicketsTaken.length > 0) {
        charts.push(getLastFiveWicketsTakenAcrossFormatsChart(player.wicketsTaken, player.internationalT20WicketsTaken, player.domesticT20WicketsTaken, player.internationalT20LeagueWicketsTaken));
        charts.push(getEconomyRateAndOversBowledAcrossFormatsChart(player.wicketsTaken, player.internationalT20WicketsTaken, player.domesticT20WicketsTaken, player.internationalT20LeagueWicketsTaken));
      }
      break;
    default:
      break;
  }

  return (
    <div className="statsNguts__player_chartContainer">
      <button type="button" onClick={() => setIsChartExpanded(!isChartExpanded)} className="chartToggle">
        Trends
      </button>
      {isChartExpanded && charts.map((chart) => (
        <div className="statsNguts__player_chartContainer_chart">
          <Chart
            chartType={chart.chartType}
            data={chart.data}
            options={chart.options}
          />
        </div>
      ))}
    </div>
  );
};

export default PlayerCharts;
