/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import './team.css';
import { getTeamTopPerformers } from '../utils/StatsUtil.jsx';

const Team = ({ playerStats, matchInfo }) => {
  const teamTopPerformers = getTeamTopPerformers(playerStats);
  const { teamAStats, teamBStats } = teamTopPerformers;

  const statTitles = {
    runsScored: 'Total Runs',
    ballsFaced: 'Balls Faced',
    strikeRate: 'Strike Rate',
    wicketsTaken: 'Total Wickets',
    ballsBowled: 'Balls Bowled',
    runsGiven: 'Runs Given',
    economyRate: 'Economy Rate',
  };

  const teamAStatOrder = {
    'Most Runs': ['runsScored', 'ballsFaced', 'strikeRate'],
    'Best Strike Rate': ['strikeRate', 'runsScored', 'ballsFaced'],
    'Most Wickets': ['wicketsTaken', 'ballsBowled', 'runsGiven', 'economyRate'],
    'Best Economy Rate': ['economyRate', 'ballsBowled', 'runsGiven', 'wicketsTaken'],
  };

  const teamBStatOrder = {
    'Most Runs': ['runsScored', 'ballsFaced', 'strikeRate'],
    'Best Strike Rate': ['strikeRate', 'runsScored', 'ballsFaced'],
    'Most Wickets': ['wicketsTaken', 'ballsBowled', 'runsGiven', 'economyRate'],
    'Best Economy Rate': ['economyRate', 'ballsBowled', 'runsGiven', 'wicketsTaken'],
  };

  const renderStats = (stats, statOrder) => statOrder.map((key, index) => {
    if (stats[key] !== undefined) {
      const isFirstStat = index === 0;
      return (
        <p key={key} className={isFirstStat ? 'statsNguts__team_firstStat' : 'statsNguts__team_otherStats'}>{`${statTitles[key]}: ${stats[key]}`}</p>
      );
    }
    return null;
  });

  return (
    <div className="statsNguts__team_statsContainer">
      {teamAStats.length !== 0 && (
        <div className="statsNguts__team_stats">
          <div className="statsNguts__team_headerContainer">
            <button type="button" className="statsNguts__team_headerButton">{matchInfo.teamAShortName} Top Performers from Player&apos;s Last 10 Innings</button>
          </div>
          {teamAStats.map((stat, index) => (
            <div className="statsNguts__team_statContainer" key={index}>
              <div className="statsNguts__team_statHeader">{stat.title}</div>
              <div className="statsNguts__team_playerStatContainer">
                <div className="statsNguts__team_playerImageContainer">
                  <div className="statsNguts__team_playerImage">
                    <img src={require(`../../../../assets/image/players/${stat.playerImage}`)} alt={stat.playerName} />
                  </div>
                </div>
                <div className="statsNguts__team_statInfo">
                  <p className="statsNguts__team_playerName">{stat.playerName}</p>
                  {renderStats(stat.stat, teamAStatOrder[stat.title])}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {teamBStats.length !== 0 && (
        <div className="statsNguts__team_stats">
          <div className="statsNguts__team_headerContainer">
            <button type="button" className="statsNguts__team_headerButton">{matchInfo.teamBShortName} Top Performers from Player&apos;s Last 10 Innings</button>
          </div>
          {teamBStats.map((stat, index) => (
            <div className="statsNguts__team_statContainer" key={index}>
              <div className="statsNguts__team_statHeader">{stat.title}</div>
              <div className="statsNguts__team_playerStatContainer">
                <div className="statsNguts__team_playerImageContainer">
                  <div className="statsNguts__team_playerImage">
                    <img src={require(`../../../../assets/image/players/${stat.playerImage}`)} alt={stat.playerName} />
                  </div>
                </div>
                <div className="statsNguts__team_statInfo">
                  <p className="statsNguts__team_playerName">{stat.playerName}</p>
                  {renderStats(stat.stat, teamBStatOrder[stat.title])}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Team;
