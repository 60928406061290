import React from 'react';

const BestEconomyRate = ({ items }) => {
  const bestTwoEconomyRate = items.slice(0, 2);

  return (
    <div>
      <div className="statsNguts__matchAnalysis_categoryTitle">Best Economy Rate</div>
      {bestTwoEconomyRate.map((item, index) => (
        <div className="statsNguts__matchAnalysis_categoryItem" key={index}> <strong>{item.Name}</strong> - {item.EconomyRate} ({item.Balls} Balls)</div>
      ))}
    </div>
  );
};

export default BestEconomyRate;
