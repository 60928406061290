import React from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import './earlyAccess.css';

const EarlyAccess = () => {
  const navigate = useNavigate();
  const handleSignupClick = () => {
    ReactGA.event({
      category: 'User Actions',
      action: 'SignUp-Click',
      label: 'UserSignUp',
    });
    navigate('/login');
  };

  return (
    <div className="statsNguts__earlyAccess section__padding">
      <div className="statsNguts__earlyAccess_heading">
        <h1 className="gradient__text">Register to never miss a fantasy match update!</h1>
      </div>

      <div className="statsNguts__earlyAccess_btn" onClick={handleSignupClick}>
        <p>Register Now</p>
      </div>
    </div>
  );
};

export default EarlyAccess;
