/* eslint-disable no-loop-func */
/* eslint-disable no-param-reassign */
import { Case1GetFantasyPlayers } from './Case1.jsx';
import { Case2GetFantasyPlayers } from './Case2.jsx';
import { Case3GetFantasyPlayers } from './Case3.jsx';
import { Case4GetFantasyPlayers } from './Case4.jsx';
import { Case5GetFantasyPlayers } from './Case5.jsx';
import { Case6GetFantasyPlayers } from './Case6.jsx';
import { Case7GetFantasyPlayers } from './Case7.jsx';
import { Case8GetFantasyPlayers } from './Case8.jsx';

export const GetFantasyTeams = (answers, playerStats, matchInfo) => {
  let teamAfantasyPlayerList = [];
  let teamAPlayingWicketKeepers = [];
  let teamAPlayingBatters = [];
  let teamAPlayingAllRounders = [];
  let teamAPlayingBowlers = [];
  let teamBfantasyPlayerList = [];
  let teamBPlayingWicketKeepers = [];
  let teamBPlayingBatters = [];
  let teamBPlayingAllRounders = [];
  let teamBPlayingBowlers = [];

  teamAfantasyPlayerList = matchInfo.teamAPlayingSquad;
  teamBfantasyPlayerList = matchInfo.teamBPlayingSquad;

  const teamAWicketkeepers = playerStats.teamA_WicketKeepers.filter((player) => player.status === 'Available');
  const teamABatters = playerStats.teamA_Batters.filter((player) => player.status === 'Available');
  const teamAAllRounders = playerStats.teamA_AllRounders.filter((player) => player.status === 'Available');
  const teamABowlers = playerStats.teamA_Bowlers.filter((player) => player.status === 'Available');
  const teamBWicketkeepers = playerStats.teamB_WicketKeepers.filter((player) => player.status === 'Available');
  const teamBBatters = playerStats.teamB_Batters.filter((player) => player.status === 'Available');
  const teamBAllRounders = playerStats.teamB_AllRounders.filter((player) => player.status === 'Available');
  const teamBBowlers = playerStats.teamB_Bowlers.filter((player) => player.status === 'Available');

  if (teamAfantasyPlayerList.length > 0) {
    teamAWicketkeepers.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingWicketKeepers.push(player);
      }
    });

    teamABatters.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingBatters.push(player);
      }
    });

    teamAAllRounders.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingAllRounders.push(player);
      }
    });

    teamABowlers.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingBowlers.push(player);
      }
    });
  } else {
    teamAPlayingWicketKeepers = teamAWicketkeepers;
    teamAPlayingBatters = teamABatters;
    teamAPlayingAllRounders = teamAAllRounders;
    teamAPlayingBowlers = teamABowlers;
  }

  if (teamBfantasyPlayerList.length > 0) {
    teamBWicketkeepers.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingWicketKeepers.push(player);
      }
    });

    teamBBatters.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingBatters.push(player);
      }
    });

    teamBAllRounders.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingAllRounders.push(player);
      }
    });

    teamBBowlers.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingBowlers.push(player);
      }
    });
  } else {
    teamBPlayingWicketKeepers = teamBWicketkeepers;
    teamBPlayingBatters = teamBBatters;
    teamBPlayingAllRounders = teamBAllRounders;
    teamBPlayingBowlers = teamBBowlers;
  }

  const playingPlayers = {
    teamA_WicketKeepers: teamAPlayingWicketKeepers,
    teamA_Batters: teamAPlayingBatters,
    teamA_AllRounders: teamAPlayingAllRounders,
    teamA_Bowlers: teamAPlayingBowlers,
    teamB_WicketKeepers: teamBPlayingWicketKeepers,
    teamB_Batters: teamBPlayingBatters,
    teamB_AllRounders: teamBPlayingAllRounders,
    teamB_Bowlers: teamBPlayingBowlers,
  };

  const fantasyPlayers = [];
  const fantasyWicketKeepers = [];
  const fantasyBatters = [];
  const fantasyAllRounders = [];
  const fantasyBowlers = [];
  const a1 = answers[0];
  const a2 = answers[1];
  const a3 = answers[2];
  const a4 = answers[3];
  const a5 = answers[4];
  const a6 = answers[5];
  const a7 = answers[6];
  const a8 = answers[7];
  const a9 = answers[8];
  const a10 = answers[9];
  const a11 = answers[10];
  const a12 = answers[11];

  // Add User Selected Wicket Keeper
  fantasyWicketKeepers.push(a5);
  fantasyPlayers.push(a5);
  let wk = teamAPlayingWicketKeepers.find((player) => player.name === a5);
  if (wk) {
    teamAPlayingWicketKeepers = teamAPlayingWicketKeepers.filter((item) => item.name !== a5);
  }
  wk = teamBPlayingWicketKeepers.find((player) => player.name === a5);
  if (wk) {
    teamBPlayingWicketKeepers = teamBPlayingWicketKeepers.filter((item) => item.name !== a5);
  }

  // Add User Selected Batter
  fantasyBatters.push(a6);
  fantasyPlayers.push(a6);
  let bt = teamAPlayingBatters.find((player) => player.name === a6);
  if (bt) {
    teamAPlayingBatters = teamAPlayingBatters.filter((item) => item.name !== a6);
  }
  bt = teamBPlayingBatters.find((player) => player.name === a6);
  if (bt) {
    teamBPlayingBatters = teamBPlayingBatters.filter((item) => item.name !== a6);
  }

  // Add User Selected All Rounder
  fantasyAllRounders.push(a7);
  fantasyPlayers.push(a7);
  let ar = teamAPlayingAllRounders.find((player) => player.name === a7);
  if (ar) {
    teamAPlayingAllRounders = teamAPlayingAllRounders.filter((item) => item.name !== a7);
  }
  ar = teamBPlayingAllRounders.find((player) => player.name === a7);
  if (ar) {
    teamBPlayingAllRounders = teamBPlayingAllRounders.filter((item) => item.name !== a7);
  }

  // Add User Selected Bowler
  fantasyBowlers.push(a8);
  fantasyPlayers.push(a8);
  let bw = teamAPlayingBowlers.find((player) => player.name === a8);
  if (bw) {
    teamAPlayingBowlers = teamAPlayingBowlers.filter((item) => item.name !== a8);
  }
  bw = teamBPlayingBowlers.find((player) => player.name === a8);
  if (bw) {
    teamBPlayingBowlers = teamBPlayingBowlers.filter((item) => item.name !== a8);
  }

  // Check if a9 is equal to the already selected Wicket Keeper or Batter or All Rounder
  if (!fantasyPlayers.includes(a9)) {
    fantasyPlayers.push(a9);
    wk = teamAPlayingWicketKeepers.find((player) => player.name === a9);
    if (wk) {
      teamAPlayingWicketKeepers = teamAPlayingWicketKeepers.filter((item) => item.name !== a9);
      fantasyWicketKeepers.push(a9);
    }
    wk = teamBPlayingWicketKeepers.find((player) => player.name === a9);
    if (wk) {
      teamBPlayingWicketKeepers = teamBPlayingWicketKeepers.filter((item) => item.name !== a9);
      fantasyWicketKeepers.push(a9);
    }
    bt = teamAPlayingBatters.find((player) => player.name === a9);
    if (bt) {
      teamAPlayingBatters = teamAPlayingBatters.filter((item) => item.name !== a9);
      fantasyBatters.push(a9);
    }
    bt = teamBPlayingBatters.find((player) => player.name === a9);
    if (bt) {
      teamBPlayingBatters = teamBPlayingBatters.filter((item) => item.name !== a9);
      fantasyBatters.push(a9);
    }
    ar = teamAPlayingAllRounders.find((player) => player.name === a9);
    if (ar) {
      teamAPlayingAllRounders = teamAPlayingAllRounders.filter((item) => item.name !== a9);
      fantasyAllRounders.push(a9);
    }
    ar = teamBPlayingAllRounders.find((player) => player.name === a9);
    if (ar) {
      teamBPlayingAllRounders = teamBPlayingAllRounders.filter((item) => item.name !== a9);
      fantasyAllRounders.push(a9);
    }
  }

  // Check if a10 is equal to the already selected Wicket Keeper or Batter or All Rounder
  if (!fantasyPlayers.includes(a10)) {
    fantasyPlayers.push(a10);
    wk = teamAPlayingWicketKeepers.find((player) => player.name === a10);
    if (wk) {
      teamAPlayingWicketKeepers = teamAPlayingWicketKeepers.filter((item) => item.name !== a10);
      fantasyWicketKeepers.push(a10);
    }
    wk = teamBPlayingWicketKeepers.find((player) => player.name === a10);
    if (wk) {
      teamBPlayingWicketKeepers = teamBPlayingWicketKeepers.filter((item) => item.name !== a10);
      fantasyWicketKeepers.push(a10);
    }
    bt = teamAPlayingBatters.find((player) => player.name === a10);
    if (bt) {
      teamAPlayingBatters = teamAPlayingBatters.filter((item) => item.name !== a10);
      fantasyBatters.push(a10);
    }
    bt = teamBPlayingBatters.find((player) => player.name === a10);
    if (bt) {
      teamBPlayingBatters = teamBPlayingBatters.filter((item) => item.name !== a10);
      fantasyBatters.push(a10);
    }
    ar = teamAPlayingAllRounders.find((player) => player.name === a10);
    if (ar) {
      teamAPlayingAllRounders = teamAPlayingAllRounders.filter((item) => item.name !== a10);
      fantasyAllRounders.push(a10);
    }
    ar = teamBPlayingAllRounders.find((player) => player.name === a10);
    if (ar) {
      teamBPlayingAllRounders = teamBPlayingAllRounders.filter((item) => item.name !== a10);
      fantasyAllRounders.push(a10);
    }
  }

  // Check if a11 is equal to the already selected All Rounder or Bowler
  if (!fantasyPlayers.includes(a11)) {
    fantasyPlayers.push(a11);
    ar = teamAPlayingAllRounders.find((player) => player.name === a11);
    if (ar) {
      teamAPlayingAllRounders = teamAPlayingAllRounders.filter((item) => item.name !== a11);
      fantasyAllRounders.push(a11);
    }
    ar = teamBPlayingAllRounders.find((player) => player.name === a11);
    if (ar) {
      teamBPlayingAllRounders = teamBPlayingAllRounders.filter((item) => item.name !== a11);
      fantasyAllRounders.push(a11);
    }
    bw = teamAPlayingBowlers.find((player) => player.name === a11);
    if (bw) {
      teamAPlayingBowlers = teamAPlayingBowlers.filter((item) => item.name !== a11);
      fantasyBowlers.push(a11);
    }
    bw = teamBPlayingBowlers.find((player) => player.name === a11);
    if (bw) {
      teamBPlayingBowlers = teamBPlayingBowlers.filter((item) => item.name !== a11);
      fantasyBowlers.push(a11);
    }
  }

  // Check if a12 is equal to the already selected All Rounder or Bowler
  if (!fantasyPlayers.includes(a12)) {
    fantasyPlayers.push(a12);
    ar = teamAPlayingAllRounders.find((player) => player.name === a12);
    if (ar) {
      teamAPlayingAllRounders = teamAPlayingAllRounders.filter((item) => item.name !== a12);
      fantasyAllRounders.push(a12);
    }
    ar = teamBPlayingAllRounders.find((player) => player.name === a12);
    if (ar) {
      teamBPlayingAllRounders = teamBPlayingAllRounders.filter((item) => item.name !== a12);
      fantasyAllRounders.push(a12);
    }
    bw = teamAPlayingBowlers.find((player) => player.name === a12);
    if (bw) {
      teamAPlayingBowlers = teamAPlayingBowlers.filter((item) => item.name !== a12);
      fantasyBowlers.push(a12);
    }
    bw = teamBPlayingBowlers.find((player) => player.name === a12);
    if (bw) {
      teamBPlayingBowlers = teamBPlayingBowlers.filter((item) => item.name !== a12);
      fantasyBowlers.push(a12);
    }
  }

  // After this step, there are 3 possibilities
  // Either 3 slots are left
  // OR 4 slots are left
  // OR 5 slots are left
  // OR 6 slots are left

  // Check number of slots left
  const fantasyPlayersSlotsLeft = 11 - fantasyPlayers.length;
  const numberOfTeamsAnswer = answers[12];
  let results = '';

  // Case1, HighScoring(a2)=Yes, OutMatch(a3)=Yes, Spinners(a4)=Yes
  // SubCase1 = TeamA to Win(a1)
  if (a2 === 'Yes' && a3 === 'Yes' && a4 === 'Yes' && a1 === matchInfo.teamAShortName) {
    results = Case1GetFantasyPlayers(playingPlayers, 'TeamA', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }
  // SubCase2 = TeamB to Win(a1)
  if (a2 === 'Yes' && a3 === 'Yes' && a4 === 'Yes' && a1 === matchInfo.teamBShortName) {
    results = Case1GetFantasyPlayers(playingPlayers, 'TeamB', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }

  // Case2, HighScoring=Yes, OutMatch=Yes, Spinners=No
  // SubCase1 = TeamA to Win(a1)
  if (a2 === 'Yes' && a3 === 'Yes' && a4 === 'No' && a1 === matchInfo.teamAShortName) {
    results = Case2GetFantasyPlayers(playingPlayers, 'TeamA', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }
  // SubCase2 = TeamB to Win(a1)
  if (a2 === 'Yes' && a3 === 'Yes' && a4 === 'No' && a1 === matchInfo.teamBShortName) {
    results = Case2GetFantasyPlayers(playingPlayers, 'TeamB', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }

  // Case3, HighScoring=Yes, OutMatch=No, Spinners=Yes
  // SubCase1 = TeamA to Win(a1)
  if (a2 === 'Yes' && a3 === 'No' && a4 === 'Yes' && a1 === matchInfo.teamAShortName) {
    results = Case3GetFantasyPlayers(playingPlayers, 'TeamA', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }
  // SubCase2 = TeamB to Win(a1)
  if (a2 === 'Yes' && a3 === 'No' && a4 === 'Yes' && a1 === matchInfo.teamBShortName) {
    results = Case3GetFantasyPlayers(playingPlayers, 'TeamB', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }

  // Case4, HighScoring=Yes, OutMatch=No, Spinners=No
  // SubCase1 = TeamA to Win(a1)
  if (a2 === 'Yes' && a3 === 'No' && a4 === 'No' && a1 === matchInfo.teamAShortName) {
    results = Case4GetFantasyPlayers(playingPlayers, 'TeamA', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }
  // SubCase2 = TeamB to Win(a1)
  if (a2 === 'Yes' && a3 === 'No' && a4 === 'No' && a1 === matchInfo.teamBShortName) {
    results = Case4GetFantasyPlayers(playingPlayers, 'TeamB', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }

  // Case5, HighScoring=No, OutMatch=Yes, Spinners=Yes
  // SubCase1 = TeamA to Win(a1)
  if (a2 === 'No' && a3 === 'Yes' && a4 === 'Yes' && a1 === matchInfo.teamAShortName) {
    results = Case5GetFantasyPlayers(playingPlayers, 'TeamA', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }
  // SubCase2 = TeamB to Win(a1)
  if (a2 === 'No' && a3 === 'Yes' && a4 === 'Yes' && a1 === matchInfo.teamBShortName) {
    results = Case5GetFantasyPlayers(playingPlayers, 'TeamB', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }

  // Case6, HighScoring=No, OutMatch=Yes, Spinners=No
  // SubCase1 = TeamA to Win(a1)
  if (a2 === 'No' && a3 === 'Yes' && a4 === 'No' && a1 === matchInfo.teamAShortName) {
    results = Case6GetFantasyPlayers(playingPlayers, 'TeamA', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }
  // SubCase2 = TeamB to Win(a1)
  if (a2 === 'No' && a3 === 'Yes' && a4 === 'No' && a1 === matchInfo.teamBShortName) {
    results = Case6GetFantasyPlayers(playingPlayers, 'TeamB', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }

  // Case7, HighScoring=No, OutMatch=No, Spinners=Yes
  // SubCase1 = TeamA to Win(a1)
  if (a2 === 'No' && a3 === 'No' && a4 === 'Yes' && a1 === matchInfo.teamAShortName) {
    results = Case7GetFantasyPlayers(playingPlayers, 'TeamA', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }
  // SubCase2 = TeamB to Win(a1)
  if (a2 === 'No' && a3 === 'No' && a4 === 'Yes' && a1 === matchInfo.teamBShortName) {
    results = Case7GetFantasyPlayers(playingPlayers, 'TeamB', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }

  // Case8, HighScoring=No, OutMatch=No, Spinners=No
  // SubCase1 = TeamA to Win(a1)
  if (a2 === 'No' && a3 === 'No' && a4 === 'No' && a1 === matchInfo.teamAShortName) {
    results = Case8GetFantasyPlayers(playingPlayers, 'TeamA', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }
  // SubCase2 = TeamB to Win(a1)
  if (a2 === 'No' && a3 === 'No' && a4 === 'No' && a1 === matchInfo.teamBShortName) {
    results = Case8GetFantasyPlayers(playingPlayers, 'TeamB', numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAPlayingWicketKeepers, teamAPlayingBatters, teamAPlayingAllRounders, teamAPlayingBowlers, teamBPlayingWicketKeepers, teamBPlayingBatters, teamBPlayingAllRounders, teamBPlayingBowlers);
  }

  const fantasyWicketKeepersArray = [];
  const fantasyBattersArray = [];
  const fantasyallRoundersArray = [];
  const fantasyBowlersArray = [];

  results.forEach((result) => {
    const wicketKeepersFullList = [];
    const battersFullList = [];
    const allRoundersFullList = [];
    const bowlersFullList = [];

    result.fantasyWicketKeepers.forEach((element) => {
      wicketKeepersFullList.push(element.Name);
    });
    result.fantasyBatters.forEach((element) => {
      battersFullList.push(element.Name);
    });
    result.fantasyAllRounders.forEach((element) => {
      allRoundersFullList.push(element.Name);
    });
    result.fantasyBowlers.forEach((element) => {
      bowlersFullList.push(element.Name);
    });

    fantasyWicketKeepers.forEach((name) => {
      wicketKeepersFullList.push(name);
    });
    fantasyBatters.forEach((name) => {
      battersFullList.push(name);
    });
    fantasyAllRounders.forEach((name) => {
      allRoundersFullList.push(name);
    });
    fantasyBowlers.forEach((name) => {
      bowlersFullList.push(name);
    });

    fantasyWicketKeepersArray.push(wicketKeepersFullList);
    fantasyBattersArray.push(battersFullList);
    fantasyallRoundersArray.push(allRoundersFullList);
    fantasyBowlersArray.push(bowlersFullList);
  });

  const fantasyTeams = [];
  for (let i = 0; i < numberOfTeamsAnswer; i += 1) {
    const teamNumber = i + 1;
    const teamName = `Team ${teamNumber}`;
    const players = [];
    fantasyWicketKeepersArray[i].forEach((name) => {
      let element = playerStats.teamA_WicketKeepers.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'WicketKeeper',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }

      element = playerStats.teamB_WicketKeepers.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'WicketKeeper',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }
    });

    fantasyBattersArray[i].forEach((name) => {
      let element = playerStats.teamA_Batters.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'Batter',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }

      element = playerStats.teamB_Batters.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'Batter',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }
    });

    fantasyallRoundersArray[i].forEach((name) => {
      let element = playerStats.teamA_AllRounders.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'AllRounder',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }

      element = playerStats.teamB_AllRounders.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'AllRounder',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }
    });

    fantasyBowlersArray[i].forEach((name) => {
      let element = playerStats.teamA_Bowlers.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'Bowler',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }

      element = playerStats.teamB_Bowlers.find((entry) => entry.name === name);
      if (element) {
        const player = {
          name: element.name,
          role: 'Bowler',
          avatar: element.avatar,
          isViceCaptain: false,
          isCaptain: false,
        };

        players.push(player);
      }
    });

    players.forEach((player) => {
      if (player.name === results[i].captain) {
        player.isCaptain = true;
      }
      if (player.name === results[i].viceCaptain) {
        player.isViceCaptain = true;
      }
    });

    const fantasyTeam = {
      teamName,
      players,
    };

    fantasyTeams.push(fantasyTeam);
  }

  return fantasyTeams;
};
