import React from 'react';
import Teamh2h from '../teamh2h/Teamh2h';
import './teamStats.css';

const TeamStats = React.forwardRef(({ matchInfo, teamStats, playerStats }, ref) => (
  <div ref={ref}>
    <div className="statsNguts__teamStats" id="teamStats">
      <div className="statsNguts__teamStats_heading">
        <h1 className="gradient__text">Team Stats</h1>
        <h3>Statistics from last 10 head to head T20Is, and last 10 T20Is of respective teams
        </h3>
      </div>
      <Teamh2h playerStats={playerStats} teamStats={teamStats} matchInfo={matchInfo} />
    </div>
  </div>
));

export default TeamStats;
