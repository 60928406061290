import React from 'react';
import './seriesDescription.css';

const SeriesDescription = () => (
  <div className="statsNguts__seriesDescription section__padding" id="seriesDescription">
    <div className="statsNguts__seriesDescription_heading">
      <h1 className="gradient__text">Indian Premier League 2024</h1>
    </div>
  </div>
);

export default SeriesDescription;
