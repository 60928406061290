import React, { useState } from 'react';
import parse from 'html-react-parser';
import './teamh2hOverview.css';

const Teamh2hOverview = ({ teamStats }) => {
  const [isOverviewExpanded, setIsOverviewExpanded] = useState(true);
  const teamh2hOverview = JSON.stringify(teamStats.overview);

  return (
    <div className="statsNguts__teamh2h_overviewContainer">
      <button type="button" onClick={() => setIsOverviewExpanded(!isOverviewExpanded)} className="overviewToggle">
        Overview
      </button>
      {isOverviewExpanded && (
      <div className="teamh2hOverviewContent">
        <h3>{parse(teamh2hOverview)}</h3>
      </div>
      )}
    </div>
  );
};

export default Teamh2hOverview;
