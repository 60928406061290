/* eslint-disable no-param-reassign */
import { getTopRunScorers, getTopWicketTakers } from './fantasyTeamsStatsUtil.jsx';

// Case4, HighScoring=Yes, OutMatch=No, Spinners=No
export const Case4GetFantasyPlayers = (playerStats, winningTeam, numberOfTeamsAnswer, fantasyPlayersSlotsLeft, teamAWicketkeepers, teamABatters, teamAAllRounders, teamABowlers, teamBWicketkeepers, teamBBatters, teamBAllRounders, teamBBowlers) => {
  // Generate Set1 Team and Set2 Team
  // numberOfTeamsAnswer = '1'
  // Set1 Team - Captain: Winning Team (TopRunScorer), Vice-Captain: Losing Team (TopRunScorer)
  // numberOfTeamsAnswer = '2'
  // Set1 Team - Captain: Winning Team (TopRunScorer), Vice-Captain: Losing Team (TopRunScorer)
  // Set1 Team - Captain: Losing Team (TopRunScorer), Vice-Captain: Winning Team (TopRunScorer)
  // numberOfTeamsAnswer = '3'
  // Set1 Team - Captain: Winning Team (TopRunScorer), Vice-Captain: Losing Team (TopRunScorer)
  // Set1 Team - Captain: Losing Team (TopRunScorer), Vice-Captain: Winning Team (TopRunScorer)
  // Set2 Team - Captain: Winning Team (TopRunScorer), Vice-Captain: Losing Team (TopRunScorer)
  // numberOfTeamsAnswer = '4'
  // Set1 Team - Captain: Winning Team (TopRunScorer), Vice-Captain: Losing Team (TopRunScorer)
  // Set1 Team - Captain: Losing Team (TopRunScorer), Vice-Captain: Winning Team (TopRunScorer)
  // Set2 Team - Captain: Winning Team (TopRunScorer), Vice-Captain: Losing Team (TopRunScorer)
  // Set2 Team - Captain: Losing Team (TopRunScorer), Vice-Captain: Winning Team (TopRunScorer)

  const results = [];
  const availableTeamAWicketkeepers = playerStats.teamA_WicketKeepers;
  const availableTeamABatters = playerStats.teamA_Batters;
  const availableTeamAAllRounders = playerStats.teamA_AllRounders;
  const availableTeamBWicketkeepers = playerStats.teamB_WicketKeepers;
  const availableTeamBBatters = playerStats.teamB_Batters;
  const availableTeamBAllRounders = playerStats.teamB_AllRounders;
  const fantasyWicketKeepersSet1 = [];
  const fantasyBattersSet1 = [];
  const fantasyAllRoundersSet1 = [];
  const fantasyBowlersSet1 = [];
  const fantasyWicketKeepersSet2 = [];
  const fantasyBattersSet2 = [];
  const fantasyAllRoundersSet2 = [];
  const fantasyBowlersSet2 = [];

  let winningTeamAvailableWicketKeepersSet1 = [];
  let winningTeamAvailableWicketKeepersSet2 = [];
  let winningTeamAvailableBattersSet1 = [];
  let winningTeamAvailableBattersSet2 = [];
  let winningTeamAvailableAllRoundersSet1 = [];
  let winningTeamAvailableAllRoundersSet2 = [];
  let winningTeamAvailableBowlersSet1 = [];
  let winningTeamAvailableBowlersSet2 = [];
  let losingTeamAvailableWicketKeepersSet1 = [];
  let losingTeamAvailableWicketKeepersSet2 = [];
  let losingTeamAvailableBattersSet1 = [];
  let losingTeamAvailableBattersSet2 = [];
  let losingTeamAvailableAllRoundersSet1 = [];
  let losingTeamAvailableAllRoundersSet2 = [];
  let losingTeamAvailableBowlersSet1 = [];
  let losingTeamAvailableBowlersSet2 = [];

  let topBattersWinningTeam = [];
  let topBattersLosingTeam = [];

  if (winningTeam === 'TeamA') {
    winningTeamAvailableWicketKeepersSet1 = teamAWicketkeepers;
    winningTeamAvailableWicketKeepersSet2 = teamAWicketkeepers;
    winningTeamAvailableBattersSet1 = teamABatters;
    winningTeamAvailableBattersSet2 = teamABatters;
    winningTeamAvailableAllRoundersSet1 = teamAAllRounders;
    winningTeamAvailableAllRoundersSet2 = teamAAllRounders;
    winningTeamAvailableBowlersSet1 = teamABowlers;
    winningTeamAvailableBowlersSet2 = teamABowlers;
    losingTeamAvailableWicketKeepersSet1 = teamBWicketkeepers;
    losingTeamAvailableWicketKeepersSet2 = teamBWicketkeepers;
    losingTeamAvailableBattersSet1 = teamBBatters;
    losingTeamAvailableBattersSet2 = teamBBatters;
    losingTeamAvailableAllRoundersSet1 = teamBAllRounders;
    losingTeamAvailableAllRoundersSet2 = teamBAllRounders;
    losingTeamAvailableBowlersSet1 = teamBBowlers;
    losingTeamAvailableBowlersSet2 = teamBBowlers;
    topBattersWinningTeam = getTopRunScorers(availableTeamAWicketkeepers.concat(availableTeamABatters).concat(availableTeamAAllRounders), 1);
    topBattersLosingTeam = getTopRunScorers(availableTeamBWicketkeepers.concat(availableTeamBBatters).concat(availableTeamBAllRounders), 1);
  }

  if (winningTeam === 'TeamB') {
    winningTeamAvailableWicketKeepersSet1 = teamBWicketkeepers;
    winningTeamAvailableWicketKeepersSet2 = teamBWicketkeepers;
    winningTeamAvailableBattersSet1 = teamBBatters;
    winningTeamAvailableBattersSet2 = teamBBatters;
    winningTeamAvailableAllRoundersSet1 = teamBAllRounders;
    winningTeamAvailableAllRoundersSet2 = teamBAllRounders;
    winningTeamAvailableBowlersSet1 = teamBBowlers;
    winningTeamAvailableBowlersSet2 = teamBBowlers;
    losingTeamAvailableWicketKeepersSet1 = teamAWicketkeepers;
    losingTeamAvailableWicketKeepersSet2 = teamAWicketkeepers;
    losingTeamAvailableBattersSet1 = teamABatters;
    losingTeamAvailableBattersSet2 = teamABatters;
    losingTeamAvailableAllRoundersSet1 = teamAAllRounders;
    losingTeamAvailableAllRoundersSet2 = teamAAllRounders;
    losingTeamAvailableBowlersSet1 = teamABowlers;
    losingTeamAvailableBowlersSet2 = teamABowlers;
    topBattersWinningTeam = getTopRunScorers(availableTeamBWicketkeepers.concat(availableTeamBBatters).concat(availableTeamBAllRounders), 1);
    topBattersLosingTeam = getTopRunScorers(availableTeamAWicketkeepers.concat(availableTeamABatters).concat(availableTeamAAllRounders), 1);
  }

  function GetWicketTakers(bowlerType, count, set, team) {
    if (set === 'Set1' && team === 'WinningTeam') {
      const wicketTakers = getTopWicketTakers(winningTeamAvailableAllRoundersSet1.concat(winningTeamAvailableBowlersSet1), bowlerType, count);
      wicketTakers.forEach((element) => {
        const ar = winningTeamAvailableAllRoundersSet1.find((entry) => entry.name === element.Name);
        if (ar) {
          fantasyAllRoundersSet1.push(element);
          winningTeamAvailableAllRoundersSet1 = winningTeamAvailableAllRoundersSet1.filter((item) => item.name !== element.Name);
        }
        const bw = winningTeamAvailableBowlersSet1.find((entry) => entry.name === element.Name);
        if (bw) {
          fantasyBowlersSet1.push(element);
          winningTeamAvailableBowlersSet1 = winningTeamAvailableBowlersSet1.filter((item) => item.name !== element.Name);
        }
      });

      if (wicketTakers.length !== count) {
        const leftCount = count - wicketTakers.length;
        const wicketTakersAll = getTopWicketTakers(winningTeamAvailableAllRoundersSet1.concat(winningTeamAvailableBowlersSet1).concat(winningTeamAvailableWicketKeepersSet1).concat(winningTeamAvailableBattersSet1), 'All', leftCount);
        wicketTakersAll.forEach((element) => {
          const wk = winningTeamAvailableWicketKeepersSet1.find((entry) => entry.name === element.Name);
          if (wk) {
            fantasyWicketKeepersSet1.push(element);
            winningTeamAvailableWicketKeepersSet1 = winningTeamAvailableWicketKeepersSet1.filter((item) => item.name !== element.Name);
          }
          const bt = winningTeamAvailableBattersSet1.find((entry) => entry.name === element.Name);
          if (bt) {
            fantasyBattersSet1.push(element);
            winningTeamAvailableBattersSet1 = winningTeamAvailableBattersSet1.filter((item) => item.name !== element.Name);
          }
          const ar = winningTeamAvailableAllRoundersSet1.find((entry) => entry.name === element.Name);
          if (ar) {
            fantasyAllRoundersSet1.push(element);
            winningTeamAvailableAllRoundersSet1 = winningTeamAvailableAllRoundersSet1.filter((item) => item.name !== element.Name);
          }
          const bw = winningTeamAvailableBowlersSet1.find((entry) => entry.name === element.Name);
          if (bw) {
            fantasyBowlersSet1.push(element);
            winningTeamAvailableBowlersSet1 = winningTeamAvailableBowlersSet1.filter((item) => item.name !== element.Name);
          }
        });
      }
    }
    if (set === 'Set1' && team === 'LosingTeam') {
      const wicketTakers = getTopWicketTakers(losingTeamAvailableAllRoundersSet1.concat(losingTeamAvailableBowlersSet1), bowlerType, count);
      wicketTakers.forEach((element) => {
        const ar = losingTeamAvailableAllRoundersSet1.find((entry) => entry.name === element.Name);
        if (ar) {
          fantasyAllRoundersSet1.push(element);
          losingTeamAvailableAllRoundersSet1 = losingTeamAvailableAllRoundersSet1.filter((item) => item.name !== element.Name);
        }
        const bw = losingTeamAvailableBowlersSet1.find((entry) => entry.name === element.Name);
        if (bw) {
          fantasyBowlersSet1.push(element);
          losingTeamAvailableBowlersSet1 = losingTeamAvailableBowlersSet1.filter((item) => item.name !== element.Name);
        }
      });

      if (wicketTakers.length !== count) {
        const leftCount = count - wicketTakers.length;
        const wicketTakersAll = getTopWicketTakers(losingTeamAvailableAllRoundersSet1.concat(losingTeamAvailableBowlersSet1).concat(losingTeamAvailableWicketKeepersSet1).concat(losingTeamAvailableBattersSet1), 'All', leftCount);
        wicketTakersAll.forEach((element) => {
          const wk = losingTeamAvailableWicketKeepersSet1.find((entry) => entry.name === element.Name);
          if (wk) {
            fantasyWicketKeepersSet1.push(element);
            losingTeamAvailableWicketKeepersSet1 = losingTeamAvailableWicketKeepersSet1.filter((item) => item.name !== element.Name);
          }
          const bt = losingTeamAvailableBattersSet1.find((entry) => entry.name === element.Name);
          if (bt) {
            fantasyBattersSet1.push(element);
            losingTeamAvailableBattersSet1 = losingTeamAvailableBattersSet1.filter((item) => item.name !== element.Name);
          }
          const ar = losingTeamAvailableAllRoundersSet1.find((entry) => entry.name === element.Name);
          if (ar) {
            fantasyAllRoundersSet1.push(element);
            losingTeamAvailableAllRoundersSet1 = losingTeamAvailableAllRoundersSet1.filter((item) => item.name !== element.Name);
          }
          const bw = losingTeamAvailableBowlersSet1.find((entry) => entry.name === element.Name);
          if (bw) {
            fantasyBowlersSet1.push(element);
            losingTeamAvailableBowlersSet1 = losingTeamAvailableBowlersSet1.filter((item) => item.name !== element.Name);
          }
        });
      }
    }
    if (set === 'Set2' && team === 'WinningTeam') {
      const wicketTakers = getTopWicketTakers(winningTeamAvailableAllRoundersSet2.concat(winningTeamAvailableBowlersSet2), bowlerType, count);
      wicketTakers.forEach((element) => {
        const ar = winningTeamAvailableAllRoundersSet2.find((entry) => entry.name === element.Name);
        if (ar) {
          fantasyAllRoundersSet2.push(element);
          winningTeamAvailableAllRoundersSet2 = winningTeamAvailableAllRoundersSet2.filter((item) => item.name !== element.Name);
        }
        const bw = winningTeamAvailableBowlersSet2.find((entry) => entry.name === element.Name);
        if (bw) {
          fantasyBowlersSet2.push(element);
          winningTeamAvailableBowlersSet2 = winningTeamAvailableBowlersSet2.filter((item) => item.name !== element.Name);
        }
      });

      if (wicketTakers.length !== count) {
        const leftCount = count - wicketTakers.length;
        const wicketTakersAll = getTopWicketTakers(winningTeamAvailableAllRoundersSet2.concat(winningTeamAvailableBowlersSet2).concat(winningTeamAvailableWicketKeepersSet2).concat(winningTeamAvailableBattersSet2), 'All', leftCount);
        wicketTakersAll.forEach((element) => {
          const wk = winningTeamAvailableWicketKeepersSet2.find((entry) => entry.name === element.Name);
          if (wk) {
            fantasyWicketKeepersSet2.push(element);
            winningTeamAvailableWicketKeepersSet2 = winningTeamAvailableWicketKeepersSet2.filter((item) => item.name !== element.Name);
          }
          const bt = winningTeamAvailableBattersSet2.find((entry) => entry.name === element.Name);
          if (bt) {
            fantasyBattersSet2.push(element);
            winningTeamAvailableBattersSet2 = winningTeamAvailableBattersSet2.filter((item) => item.name !== element.Name);
          }
          const ar = winningTeamAvailableAllRoundersSet2.find((entry) => entry.name === element.Name);
          if (ar) {
            fantasyAllRoundersSet2.push(element);
            winningTeamAvailableAllRoundersSet2 = winningTeamAvailableAllRoundersSet2.filter((item) => item.name !== element.Name);
          }
          const bw = winningTeamAvailableBowlersSet2.find((entry) => entry.name === element.Name);
          if (bw) {
            fantasyBowlersSet2.push(element);
            winningTeamAvailableBowlersSet2 = winningTeamAvailableBowlersSet2.filter((item) => item.name !== element.Name);
          }
        });
      }
    }
    if (set === 'Set2' && team === 'LosingTeam') {
      const wicketTakers = getTopWicketTakers(losingTeamAvailableAllRoundersSet2.concat(losingTeamAvailableBowlersSet2), bowlerType, count);
      wicketTakers.forEach((element) => {
        const ar = losingTeamAvailableAllRoundersSet2.find((entry) => entry.name === element.Name);
        if (ar) {
          fantasyAllRoundersSet2.push(element);
          losingTeamAvailableAllRoundersSet2 = losingTeamAvailableAllRoundersSet2.filter((item) => item.name !== element.Name);
        }
        const bw = losingTeamAvailableBowlersSet2.find((entry) => entry.name === element.Name);
        if (bw) {
          fantasyBowlersSet2.push(element);
          losingTeamAvailableBowlersSet2 = losingTeamAvailableBowlersSet2.filter((item) => item.name !== element.Name);
        }
      });

      if (wicketTakers.length !== count) {
        const leftCount = count - wicketTakers.length;
        const wicketTakersAll = getTopWicketTakers(losingTeamAvailableAllRoundersSet2.concat(losingTeamAvailableBowlersSet2).concat(losingTeamAvailableWicketKeepersSet2).concat(losingTeamAvailableBattersSet2), 'All', leftCount);
        wicketTakersAll.forEach((element) => {
          const wk = losingTeamAvailableWicketKeepersSet2.find((entry) => entry.name === element.Name);
          if (wk) {
            fantasyWicketKeepersSet2.push(element);
            losingTeamAvailableWicketKeepersSet2 = losingTeamAvailableWicketKeepersSet2.filter((item) => item.name !== element.Name);
          }
          const bt = losingTeamAvailableBattersSet2.find((entry) => entry.name === element.Name);
          if (bt) {
            fantasyBattersSet2.push(element);
            losingTeamAvailableBattersSet2 = losingTeamAvailableBattersSet2.filter((item) => item.name !== element.Name);
          }
          const ar = losingTeamAvailableAllRoundersSet2.find((entry) => entry.name === element.Name);
          if (ar) {
            fantasyAllRoundersSet2.push(element);
            losingTeamAvailableAllRoundersSet2 = losingTeamAvailableAllRoundersSet2.filter((item) => item.name !== element.Name);
          }
          const bw = losingTeamAvailableBowlersSet2.find((entry) => entry.name === element.Name);
          if (bw) {
            fantasyBowlersSet2.push(element);
            losingTeamAvailableBowlersSet2 = losingTeamAvailableBowlersSet2.filter((item) => item.name !== element.Name);
          }
        });
      }
    }
  }

  function GetRunScorers(count, set, team) {
    if (set === 'Set1' && team === 'WinningTeam') {
      const runScorers = getTopRunScorers(winningTeamAvailableWicketKeepersSet1.concat(winningTeamAvailableBattersSet1).concat(winningTeamAvailableAllRoundersSet1), count);
      runScorers.forEach((element) => {
        const wk = winningTeamAvailableWicketKeepersSet1.find((entry) => entry.name === element.Name);
        if (wk) {
          fantasyWicketKeepersSet1.push(element);
          winningTeamAvailableWicketKeepersSet1 = winningTeamAvailableWicketKeepersSet1.filter((item) => item.name !== element.Name);
        }
        const bt = winningTeamAvailableBattersSet1.find((entry) => entry.name === element.Name);
        if (bt) {
          fantasyBattersSet1.push(element);
          winningTeamAvailableBattersSet1 = winningTeamAvailableBattersSet1.filter((item) => item.name !== element.Name);
        }
        const ar = winningTeamAvailableAllRoundersSet1.find((entry) => entry.name === element.Name);
        if (ar) {
          fantasyAllRoundersSet1.push(element);
          winningTeamAvailableAllRoundersSet1 = winningTeamAvailableAllRoundersSet1.filter((item) => item.name !== element.Name);
        }
      });

      if (runScorers.length !== count) {
        const leftCount = count - runScorers.length;
        const runScorersAll = getTopRunScorers(winningTeamAvailableWicketKeepersSet1.concat(winningTeamAvailableBattersSet1).concat(winningTeamAvailableAllRoundersSet1).concat(winningTeamAvailableBowlersSet1), leftCount);
        runScorersAll.forEach((element) => {
          const wk = winningTeamAvailableWicketKeepersSet1.find((entry) => entry.name === element.Name);
          if (wk) {
            fantasyWicketKeepersSet1.push(element);
            winningTeamAvailableWicketKeepersSet1 = winningTeamAvailableWicketKeepersSet1.filter((item) => item.name !== element.Name);
          }
          const bt = winningTeamAvailableBattersSet1.find((entry) => entry.name === element.Name);
          if (bt) {
            fantasyBattersSet1.push(element);
            winningTeamAvailableBattersSet1 = winningTeamAvailableBattersSet1.filter((item) => item.name !== element.Name);
          }
          const ar = winningTeamAvailableAllRoundersSet1.find((entry) => entry.name === element.Name);
          if (ar) {
            fantasyAllRoundersSet1.push(element);
            winningTeamAvailableAllRoundersSet1 = winningTeamAvailableAllRoundersSet1.filter((item) => item.name !== element.Name);
          }
          const bw = winningTeamAvailableBowlersSet1.find((entry) => entry.name === element.Name);
          if (bw) {
            fantasyBowlersSet1.push(element);
            winningTeamAvailableBowlersSet1 = winningTeamAvailableBowlersSet1.filter((item) => item.name !== element.Name);
          }
        });
      }
    }
    if (set === 'Set1' && team === 'LosingTeam') {
      const runScorers = getTopRunScorers(losingTeamAvailableWicketKeepersSet1.concat(losingTeamAvailableBattersSet1).concat(losingTeamAvailableAllRoundersSet1), count);
      runScorers.forEach((element) => {
        const wk = losingTeamAvailableWicketKeepersSet1.find((entry) => entry.name === element.Name);
        if (wk) {
          fantasyWicketKeepersSet1.push(element);
          losingTeamAvailableWicketKeepersSet1 = losingTeamAvailableWicketKeepersSet1.filter((item) => item.name !== element.Name);
        }
        const bt = losingTeamAvailableBattersSet1.find((entry) => entry.name === element.Name);
        if (bt) {
          fantasyBattersSet1.push(element);
          losingTeamAvailableBattersSet1 = losingTeamAvailableBattersSet1.filter((item) => item.name !== element.Name);
        }
        const ar = losingTeamAvailableAllRoundersSet1.find((entry) => entry.name === element.Name);
        if (ar) {
          fantasyAllRoundersSet1.push(element);
          losingTeamAvailableAllRoundersSet1 = losingTeamAvailableAllRoundersSet1.filter((item) => item.name !== element.Name);
        }
      });

      if (runScorers.length !== count) {
        const leftCount = count - runScorers.length;
        const runScorersAll = getTopRunScorers(losingTeamAvailableWicketKeepersSet1.concat(losingTeamAvailableBattersSet1).concat(losingTeamAvailableAllRoundersSet1).concat(losingTeamAvailableBowlersSet1), leftCount);
        runScorersAll.forEach((element) => {
          const wk = losingTeamAvailableWicketKeepersSet1.find((entry) => entry.name === element.Name);
          if (wk) {
            fantasyWicketKeepersSet1.push(element);
            losingTeamAvailableWicketKeepersSet1 = losingTeamAvailableWicketKeepersSet1.filter((item) => item.name !== element.Name);
          }
          const bt = losingTeamAvailableBattersSet1.find((entry) => entry.name === element.Name);
          if (bt) {
            fantasyBattersSet1.push(element);
            losingTeamAvailableBattersSet1 = losingTeamAvailableBattersSet1.filter((item) => item.name !== element.Name);
          }
          const ar = losingTeamAvailableAllRoundersSet1.find((entry) => entry.name === element.Name);
          if (ar) {
            fantasyAllRoundersSet1.push(element);
            losingTeamAvailableAllRoundersSet1 = losingTeamAvailableAllRoundersSet1.filter((item) => item.name !== element.Name);
          }
          const bw = losingTeamAvailableBowlersSet1.find((entry) => entry.name === element.Name);
          if (bw) {
            fantasyBowlersSet1.push(element);
            losingTeamAvailableBowlersSet1 = losingTeamAvailableBowlersSet1.filter((item) => item.name !== element.Name);
          }
        });
      }
    }
    if (set === 'Set2' && team === 'WinningTeam') {
      const runScorers = getTopRunScorers(winningTeamAvailableWicketKeepersSet2.concat(winningTeamAvailableBattersSet2).concat(winningTeamAvailableAllRoundersSet2), count);
      runScorers.forEach((element) => {
        const wk = winningTeamAvailableWicketKeepersSet2.find((entry) => entry.name === element.Name);
        if (wk) {
          fantasyWicketKeepersSet2.push(element);
          winningTeamAvailableWicketKeepersSet2 = winningTeamAvailableWicketKeepersSet2.filter((item) => item.name !== element.Name);
        }
        const bt = winningTeamAvailableBattersSet2.find((entry) => entry.name === element.Name);
        if (bt) {
          fantasyBattersSet2.push(element);
          winningTeamAvailableBattersSet2 = winningTeamAvailableBattersSet2.filter((item) => item.name !== element.Name);
        }
        const ar = winningTeamAvailableAllRoundersSet2.find((entry) => entry.name === element.Name);
        if (ar) {
          fantasyAllRoundersSet2.push(element);
          winningTeamAvailableAllRoundersSet2 = winningTeamAvailableAllRoundersSet2.filter((item) => item.name !== element.Name);
        }
      });

      if (runScorers.length !== count) {
        const leftCount = count - runScorers.length;
        const runScorersAll = getTopRunScorers(winningTeamAvailableWicketKeepersSet2.concat(winningTeamAvailableBattersSet2).concat(winningTeamAvailableAllRoundersSet2).concat(winningTeamAvailableBowlersSet2), leftCount);
        runScorersAll.forEach((element) => {
          const wk = winningTeamAvailableWicketKeepersSet2.find((entry) => entry.name === element.Name);
          if (wk) {
            fantasyWicketKeepersSet2.push(element);
            winningTeamAvailableWicketKeepersSet2 = winningTeamAvailableWicketKeepersSet2.filter((item) => item.name !== element.Name);
          }
          const bt = winningTeamAvailableBattersSet2.find((entry) => entry.name === element.Name);
          if (bt) {
            fantasyBattersSet2.push(element);
            winningTeamAvailableBattersSet2 = winningTeamAvailableBattersSet2.filter((item) => item.name !== element.Name);
          }
          const ar = winningTeamAvailableAllRoundersSet2.find((entry) => entry.name === element.Name);
          if (ar) {
            fantasyAllRoundersSet2.push(element);
            winningTeamAvailableAllRoundersSet2 = winningTeamAvailableAllRoundersSet2.filter((item) => item.name !== element.Name);
          }
          const bw = winningTeamAvailableBowlersSet2.find((entry) => entry.name === element.Name);
          if (bw) {
            fantasyBowlersSet2.push(element);
            winningTeamAvailableBowlersSet2 = winningTeamAvailableBowlersSet2.filter((item) => item.name !== element.Name);
          }
        });
      }
    }
    if (set === 'Set2' && team === 'LosingTeam') {
      const runScorers = getTopRunScorers(losingTeamAvailableWicketKeepersSet2.concat(losingTeamAvailableBattersSet2).concat(losingTeamAvailableAllRoundersSet2), count);
      runScorers.forEach((element) => {
        const wk = losingTeamAvailableWicketKeepersSet2.find((entry) => entry.name === element.Name);
        if (wk) {
          fantasyWicketKeepersSet2.push(element);
          losingTeamAvailableWicketKeepersSet2 = losingTeamAvailableWicketKeepersSet2.filter((item) => item.name !== element.Name);
        }
        const bt = losingTeamAvailableBattersSet2.find((entry) => entry.name === element.Name);
        if (bt) {
          fantasyBattersSet2.push(element);
          losingTeamAvailableBattersSet2 = losingTeamAvailableBattersSet2.filter((item) => item.name !== element.Name);
        }
        const ar = losingTeamAvailableAllRoundersSet2.find((entry) => entry.name === element.Name);
        if (ar) {
          fantasyAllRoundersSet2.push(element);
          losingTeamAvailableAllRoundersSet2 = losingTeamAvailableAllRoundersSet2.filter((item) => item.name !== element.Name);
        }
      });

      if (runScorers.length !== count) {
        const leftCount = count - runScorers.length;
        const runScorersAll = getTopRunScorers(losingTeamAvailableWicketKeepersSet2.concat(losingTeamAvailableBattersSet2).concat(losingTeamAvailableAllRoundersSet2).concat(losingTeamAvailableBowlersSet2), leftCount);
        runScorersAll.forEach((element) => {
          const wk = losingTeamAvailableWicketKeepersSet2.find((entry) => entry.name === element.Name);
          if (wk) {
            fantasyWicketKeepersSet2.push(element);
            losingTeamAvailableWicketKeepersSet2 = losingTeamAvailableWicketKeepersSet2.filter((item) => item.name !== element.Name);
          }
          const bt = losingTeamAvailableBattersSet2.find((entry) => entry.name === element.Name);
          if (bt) {
            fantasyBattersSet2.push(element);
            losingTeamAvailableBattersSet2 = losingTeamAvailableBattersSet2.filter((item) => item.name !== element.Name);
          }
          const ar = losingTeamAvailableAllRoundersSet2.find((entry) => entry.name === element.Name);
          if (ar) {
            fantasyAllRoundersSet2.push(element);
            losingTeamAvailableAllRoundersSet2 = losingTeamAvailableAllRoundersSet2.filter((item) => item.name !== element.Name);
          }
          const bw = losingTeamAvailableBowlersSet2.find((entry) => entry.name === element.Name);
          if (bw) {
            fantasyBowlersSet2.push(element);
            losingTeamAvailableBowlersSet2 = losingTeamAvailableBowlersSet2.filter((item) => item.name !== element.Name);
          }
        });
      }
    }
  }

  // Set 1 - If 3 Slots Left, Pick -> Winning Team: Top 2 Batters | Losing Team: Top Batter
  // Set 2 - If 3 Slots Left, Pick -> Winning Team: Top Batter, Top Fast Bowler | Losing Team: Top Batter

  // Set 1 - If 4 Slots Left, Pick -> Winning Team: Top 2 Batters, Top Fast Bowler | Losing Team: Top Batter
  // Set 2 - If 4 Slots Left, Pick -> Winning Team: Top Batter, Top Fast Bowler | Losing Team: Top Batter, Top Fast Bowler

  // Set 1 - If 5 Slots Left, Pick -> Winning Team: Top 2 Batters, Top Fast Bowler | Losing Team: Top 2 Batters
  // Set 2 - If 5 Slots Left, Pick -> Winning Team: Top 2 Batters, Top Fast Bowler | Losing Team: Top Batter, Top Fast Bowler

  // Set 1 - If 6 Slots Left, Pick -> Winning Team: Top 2 Batters, Top Fast Bowler | Losing Team: Top 2 Batters, Top Fast Bowler
  // Set 2 - If 6 Slots Left, Pick -> Winning Team: Top 2 Batters, Top Fast Bowler, Top Fast Bowler | Losing Team: Top Batter, Top Fast Bowler

  // Set 1 - If 7 Slots Left, Pick -> Winning Team: Top 2 Batter, Top 2 Fast Bowlers, Top Slow Bowler | Losing Team: Top Batter, Top Fast Bowler
  // Set 2 - If 7 Slots Left, Pick -> Winning Team: Top 2 Batter, Top 2 Fast Bowlers | Losing Team: Top 2 Batter, Top Fast Bowler

  if (fantasyPlayersSlotsLeft === 3) {
    // Set 1 - If 3 Slots Left, Pick -> Winning Team: Top 2 Batters | Losing Team: Top Batter
    GetRunScorers(2, 'Set1', 'WinningTeam');
    GetRunScorers(1, 'Set1', 'LosingTeam');

    // Set 2 - If 3 Slots Left, Pick -> Winning Team: Top Batter, Top Fast Bowler | Losing Team: Top Batter
    GetRunScorers(1, 'Set2', 'WinningTeam');
    GetWicketTakers('Fast', 1, 'Set2', 'WinningTeam');
    GetRunScorers(1, 'Set2', 'LosingTeam');
  }

  if (fantasyPlayersSlotsLeft === 4) {
    // Set 1 - If 4 Slots Left, Pick -> Winning Team: Top 2 Batters, Top Fast Bowler | Losing Team: Top Batter
    GetRunScorers(2, 'Set1', 'WinningTeam');
    GetWicketTakers('Fast', 1, 'Set1', 'WinningTeam');
    GetRunScorers(1, 'Set1', 'LosingTeam');

    // Set 2 - If 4 Slots Left, Pick -> Winning Team: Top Batter, Top Fast Bowler | Losing Team: Top Batter, Top Fast Bowler
    GetRunScorers(1, 'Set2', 'WinningTeam');
    GetWicketTakers('Fast', 1, 'Set2', 'WinningTeam');
    GetRunScorers(1, 'Set2', 'LosingTeam');
    GetWicketTakers('Fast', 1, 'Set2', 'LosingTeam');
  }

  if (fantasyPlayersSlotsLeft === 5) {
    // Set 1 - If 5 Slots Left, Pick -> Winning Team: Top 2 Batters, Top Fast Bowler | Losing Team: Top 2 Batters
    GetRunScorers(2, 'Set1', 'WinningTeam');
    GetWicketTakers('Fast', 1, 'Set1', 'WinningTeam');
    GetRunScorers(2, 'Set1', 'LosingTeam');

    // Set 2 - If 5 Slots Left, Pick -> Winning Team: Top 2 Batters, Top Fast Bowler | Losing Team: Top Batter, Top Fast Bowler
    GetRunScorers(2, 'Set2', 'WinningTeam');
    GetWicketTakers('Fast', 1, 'Set2', 'WinningTeam');
    GetRunScorers(1, 'Set2', 'LosingTeam');
    GetWicketTakers('Fast', 1, 'Set2', 'LosingTeam');
  }

  if (fantasyPlayersSlotsLeft === 6) {
    // Set 1 - If 6 Slots Left, Pick -> Winning Team: Top 2 Batters, Top Fast Bowler | Losing Team: Top 2 Batters, Top Fast Bowler
    GetRunScorers(2, 'Set1', 'WinningTeam');
    GetWicketTakers('Fast', 1, 'Set1', 'WinningTeam');
    GetRunScorers(2, 'Set1', 'LosingTeam');
    GetWicketTakers('Fast', 1, 'Set1', 'LosingTeam');

    // Set 2 - If 6 Slots Left, Pick -> Winning Team: Top 2 Batters, Top Fast Bowler, Top Fast Bowler | Losing Team: Top Batter, Top Fast Bowler
    GetRunScorers(2, 'Set2', 'WinningTeam');
    GetWicketTakers('Fast', 1, 'Set2', 'WinningTeam');
    GetWicketTakers('Slow', 1, 'Set2', 'WinningTeam');
    GetRunScorers(1, 'Set2', 'LosingTeam');
    GetWicketTakers('Fast', 1, 'Set2', 'LosingTeam');
  }

  if (fantasyPlayersSlotsLeft === 7) {
    // Set 1 - If 7 Slots Left, Pick -> Winning Team: Top 2 Batter, Top 2 Fast Bowlers, Top Slow Bowler | Losing Team: Top Batter, Top Fast Bowler
    GetRunScorers(2, 'Set1', 'WinningTeam');
    GetWicketTakers('Fast', 2, 'Set1', 'WinningTeam');
    GetWicketTakers('Slow', 1, 'Set1', 'WinningTeam');
    GetRunScorers(1, 'Set1', 'LosingTeam');
    GetWicketTakers('Fast', 1, 'Set1', 'LosingTeam');

    // Set 2 - If 7 Slots Left, Pick -> Winning Team: Top 2 Batter, Top 2 Fast Bowlers | Losing Team: Top 2 Batter, Top Fast Bowler
    GetRunScorers(2, 'Set2', 'WinningTeam');
    GetWicketTakers('Fast', 2, 'Set2', 'WinningTeam');
    GetRunScorers(2, 'Set2', 'LosingTeam');
    GetWicketTakers('Fast', 1, 'Set2', 'LosingTeam');
  }

  const team1 = {
    fantasyWicketKeepers: fantasyWicketKeepersSet1,
    fantasyBatters: fantasyBattersSet1,
    fantasyAllRounders: fantasyAllRoundersSet1,
    fantasyBowlers: fantasyBowlersSet1,
    captain: topBattersWinningTeam[0].Name,
    viceCaptain: topBattersLosingTeam[0].Name,
  };

  const team2 = {
    fantasyWicketKeepers: fantasyWicketKeepersSet1,
    fantasyBatters: fantasyBattersSet1,
    fantasyAllRounders: fantasyAllRoundersSet1,
    fantasyBowlers: fantasyBowlersSet1,
    captain: topBattersLosingTeam[0].Name,
    viceCaptain: topBattersWinningTeam[0].Name,
  };

  const team3 = {
    fantasyWicketKeepers: fantasyWicketKeepersSet2,
    fantasyBatters: fantasyBattersSet2,
    fantasyAllRounders: fantasyAllRoundersSet2,
    fantasyBowlers: fantasyBowlersSet2,
    captain: topBattersWinningTeam[0].Name,
    viceCaptain: topBattersLosingTeam[0].Name,
  };

  const team4 = {
    fantasyWicketKeepers: fantasyWicketKeepersSet2,
    fantasyBatters: fantasyBattersSet2,
    fantasyAllRounders: fantasyAllRoundersSet2,
    fantasyBowlers: fantasyBowlersSet2,
    captain: topBattersLosingTeam[0].Name,
    viceCaptain: topBattersWinningTeam[0].Name,
  };

  if (numberOfTeamsAnswer === '1') {
    results.push(team1);
  }

  if (numberOfTeamsAnswer === '2') {
    results.push(team1);
    results.push(team2);
  }

  if (numberOfTeamsAnswer === '3') {
    results.push(team1);
    results.push(team2);
    results.push(team3);
  }

  if (numberOfTeamsAnswer === '4') {
    results.push(team1);
    results.push(team2);
    results.push(team3);
    results.push(team4);
  }

  return results;
};

