import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navbar, About, TermsAndConditions, PrivacyPolicy, Login, EarlyAccess, Footer, Header, PublishedSeries, UpcomingSeries, CompletedSeries, Feedback } from './components/common';

import './App.css';
import AuthRoute from './AuthRoute';
import SeriesHome from './components/series/SeriesHome';
import SeriesMatchStats from './components/series/SeriesMatchStats';

const App = () => (
  <Router>
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={(
            <>
              <Navbar isMenuVisible />
              <Header />
              <PublishedSeries />
              <UpcomingSeries />
              <CompletedSeries />
              <EarlyAccess />
              <Feedback />
              <Footer />
            </>
          )}
        />
        <Route
          path="login"
          element={(
            <>
              <Navbar isMenuVisible={false} />
              <Login />
              <Footer />
            </>
          )}
        />
        <Route
          path="/about"
          element={(
            <>
              <Navbar isMenuVisible={false} />
              <About />
              <Footer />
            </>
          )}
        />
        <Route
          path="/termsandconditions"
          element={(
            <>
              <Navbar isMenuVisible={false} />
              <TermsAndConditions />
              <Footer />
            </>
          )}
        />
        <Route
          path="/privacypolicy"
          element={(
            <>
              <Navbar isMenuVisible={false} />
              <PrivacyPolicy />
              <Footer />
            </>
          )}
        />
        <Route
          path="series/:seriesId"
          element={(
            <>
              <Navbar isMenuVisible={false} />
              <SeriesHome />
              <Footer />
            </>
          )}
        />
        <Route
          path="/matchstats/:seriesId/:matchNumber/:matchId"
          element={(
            <AuthRoute element={() => (
              <>
                <Navbar isMenuVisible={false} />
                <SeriesMatchStats />
                <EarlyAccess />
                <Footer />
              </>
            )}
            />
          )}
        />
      </Routes>
    </div>
  </Router>
);

export default App;
