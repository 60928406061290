import React from 'react';
import './feedback.css';
import InstagramLogo from '../../../assets/image/instagram-logo.png';
import XLogo from '../../../assets/image/x-logo.png';
import MailIcon from '../../../assets/image/mail-icon.png';

const Feedback = () => (
  <div className="statsNguts__feedback section__padding">
    <div className="statsNguts__feedback_heading">
      <h1 className="gradient__text">Got feedback?</h1>
      <p>We would love to hear from you! Reach out through any of our channels</p>
    </div>

    <div className="statsNguts__feedback_icons">
      <a href="https://www.instagram.com/statsnguts" target="_blank" rel="noopener noreferrer" title="Instagram">
        <img src={InstagramLogo} alt="Instagram" />
      </a>
      <a href="https://twitter.com/statsnguts" target="_blank" rel="noopener noreferrer" title="X Channel">
        <img src={XLogo} alt="X Channel" />
      </a>
      <a href="mailto:connect@statsnguts.com" title="Mail">
        <img src={MailIcon} alt="Mail" />
      </a>
    </div>
  </div>
);

export default Feedback;
