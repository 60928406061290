import React from 'react';
import { useParams } from 'react-router-dom';

import CompletedMatches from './common/completedMatches/CompletedMatches';
import PublishedMatches from './common/publishedMatches/PublishedMatches';
import UpcomingMatches from './common/upcomingMatches/UpcomingMatches';
import IPL2024SeriesDescription from './IPL-2024/seriesDescription/SeriesDescription';
import ICCMensT20WC2024SeriesDescription from './ICC-Mens-T20-WC-2024/seriesDescription/SeriesDescription';
import SAVSINDT20INOV2024SeriesDescription from './SA-VS-IND-T20I-NOV-2024/seriesDescription/SeriesDescription';
import SLVSNZT20INOV2024SeriesDescription from './SL-VS-NZ-T20I-NOV-2024/seriesDescription/SeriesDescription';
import WIVSENGT20INOV2024SeriesDescription from './WI-VS-ENG-T20I-NOV-2024/seriesDescription/SeriesDescription';

const SeriesHome = () => {
  const { seriesId } = useParams();

  const componentsMap = {
    'IPL-2024': [
      <IPL2024SeriesDescription key="seriesDescIPL2024" />,
      <CompletedMatches key="completedMatchesIPL2024" seriesId={seriesId} />,
    ],
    'ICC-Mens-T20-WC-2024': [
      <ICCMensT20WC2024SeriesDescription key="seriesDescICCMensT20WC2024" />,
      <CompletedMatches key="completedMatchesICCMensT20WC2024" seriesId={seriesId} />,
    ],
    'SA-VS-IND-T20I-NOV-2024': [
      <SAVSINDT20INOV2024SeriesDescription key="seriesDescSAVSINDT20INOV2024" />,
      <PublishedMatches key="publishedMatchesSAVSINDT20INOV2024" seriesId={seriesId} />,
      <UpcomingMatches key="upcomingMatchesSAVSINDT20INOV2024" seriesId={seriesId} />,
      <CompletedMatches key="completedMatchesSAVSINDT20INOV2024" seriesId={seriesId} />,
    ],
    'SL-VS-NZ-T20I-NOV-2024': [
      <SLVSNZT20INOV2024SeriesDescription key="seriesDescSLVSNZT20INOV2024" />,
      <PublishedMatches key="publishedMatchesSLVSNZT20INOV2024" seriesId={seriesId} />,
      <UpcomingMatches key="upcomingMatchesSLVSNZT20INOV2024" seriesId={seriesId} />,
      <CompletedMatches key="completedMatchesSLVSNZT20INOV2024" seriesId={seriesId} />,
    ],
    'WI-VS-ENG-T20I-NOV-2024': [
      <WIVSENGT20INOV2024SeriesDescription key="seriesDescWIVSENGT20INOV2024" />,
      <PublishedMatches key="publishedMatchesWIVSENGT20INOV2024" seriesId={seriesId} />,
      <UpcomingMatches key="upcomingMatchesWIVSENGT20INOV2024" seriesId={seriesId} />,
      <CompletedMatches key="completedMatchesWIVSENGT20INOV2024" seriesId={seriesId} />,
    ],
  };

  const componentsToRender = componentsMap[seriesId] || null;

  return <div className="statsNguts__seriesHome">{componentsToRender}</div>;
};

export default SeriesHome;
