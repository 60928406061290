import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import people from '../../../assets/image/people.png';
import ai from '../../../assets/image/ai.png';
import './header.css';

const Header = () => {
  const { user } = useAuthenticator();

  return (
    <div className="statsNguts__header section__padding" id="home">
      <div className="statsNguts__header_content">
        <h1 className="gradient__text">Build Fantasy Teams based on Your Guts powered by Our Stats</h1>
        <p>Finding the right balance between trusting your gut and relying on statistics depends on the context. Gut feelings can offer valuable insights, especially in uncertain situations, but combining them with solid statistical analysis enhances decision-making by providing a more comprehensive understanding. It&apos;s like having both intuition and data as your allies.</p>

        <div className="statsNguts__header_content__people">
          <img src={people} alt="peopleSubscribing" />
          <p className="statsNguts__header_subscribeMessage">
            {user ? 'Thank you for subscribing to our Stats!' : <>Many users are actively subscribing to our Match Stats! If you haven&apos;t already, <span className="highlighted-text">Sign in now to subscribe!</span></>}
          </p>
        </div>
      </div>

      <div className="statsNguts__header_image">
        <img src={ai} alt="artificialIntelligence" />
      </div>
    </div>
  );
};

export default Header;
