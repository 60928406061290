import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

const AuthRoute = ({ element: Element }) => {
  const { user } = useAuthenticator();
  const location = useLocation();

  if (!user) {
    // Redirect to login page and preserve the intended path
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Element />;
};

export default AuthRoute;
