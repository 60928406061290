import React from 'react';

const MostSixesStats = ({ items }) => {
  const topTwoSixScorers = items.slice(0, 2);

  return (
    <div>
      <div className="statsNguts__matchAnalysis_categoryTitle">Most 6s</div>
      {topTwoSixScorers.map((item, index) => (
        <div className="statsNguts__matchAnalysis_categoryItem" key={index}> <strong>{item.Name}</strong> - {item.TotalSixes} Six ({item.Runs} Runs)</div>
      ))}
    </div>
  );
};

export default MostSixesStats;
