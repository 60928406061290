import React from 'react';

const MostFoursStats = ({ items }) => {
  const topTwoFoursScorers = items.slice(0, 2);

  return (
    <div>
      <div className="statsNguts__matchAnalysis_categoryTitle">Most 4s</div>
      {topTwoFoursScorers.map((item, index) => (
        <div className="statsNguts__matchAnalysis_categoryItem" key={index}> <strong>{item.Name}</strong> - {item.TotalFours} Four ({item.Runs} Runs)</div>
      ))}
    </div>
  );
};

export default MostFoursStats;
