/* eslint-disable no-lonely-if */
import React, { useState, useEffect } from 'react';
import './fantasyQuestionnaireByCombinations.css';
import FantasyTeamsByCombinations from '../fantasyTeamsByCombinations/FantasyTeamsByCombinations';
import FantasyQuestionByCombinations from '../fantasyQuestionByCombinations/FantasyQuestionByCombinations';
import {
  getTopFiveRunScorersChart,
  getTopFiveWicketTakersChart,
} from '../utils/ChartsUtil.jsx';

const FantasyQuestionnaireByCombinations = React.forwardRef(({ matchInfo, playerStats }, ref) => {
  let teamAfantasyPlayerList = [];
  let teamAPlayingWicketKeepers = [];
  let teamAPlayingBatters = [];
  let teamAPlayingAllRounders = [];
  let teamAPlayingBowlers = [];
  let teamBfantasyPlayerList = [];
  let teamBPlayingWicketKeepers = [];
  let teamBPlayingBatters = [];
  let teamBPlayingAllRounders = [];
  let teamBPlayingBowlers = [];

  teamAfantasyPlayerList = matchInfo.teamAPlayingSquad;
  teamBfantasyPlayerList = matchInfo.teamBPlayingSquad;

  const teamAWicketkeepers = playerStats.teamA_WicketKeepers.filter((player) => player.status === 'Available');
  const teamABatters = playerStats.teamA_Batters.filter((player) => player.status === 'Available');
  const teamAAllRounders = playerStats.teamA_AllRounders.filter((player) => player.status === 'Available');
  const teamABowlers = playerStats.teamA_Bowlers.filter((player) => player.status === 'Available');
  const teamBWicketkeepers = playerStats.teamB_WicketKeepers.filter((player) => player.status === 'Available');
  const teamBBatters = playerStats.teamB_Batters.filter((player) => player.status === 'Available');
  const teamBAllRounders = playerStats.teamB_AllRounders.filter((player) => player.status === 'Available');
  const teamBBowlers = playerStats.teamB_Bowlers.filter((player) => player.status === 'Available');

  if (teamAfantasyPlayerList.length > 0) {
    teamAWicketkeepers.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingWicketKeepers.push(player);
      }
    });

    teamABatters.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingBatters.push(player);
      }
    });

    teamAAllRounders.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingAllRounders.push(player);
      }
    });

    teamABowlers.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingBowlers.push(player);
      }
    });
  } else {
    teamAPlayingWicketKeepers = teamAWicketkeepers;
    teamAPlayingBatters = teamABatters;
    teamAPlayingAllRounders = teamAAllRounders;
    teamAPlayingBowlers = teamABowlers;
  }

  if (teamBfantasyPlayerList.length > 0) {
    teamBWicketkeepers.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingWicketKeepers.push(player);
      }
    });

    teamBBatters.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingBatters.push(player);
      }
    });

    teamBAllRounders.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingAllRounders.push(player);
      }
    });

    teamBBowlers.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingBowlers.push(player);
      }
    });
  } else {
    teamBPlayingWicketKeepers = teamBWicketkeepers;
    teamBPlayingBatters = teamBBatters;
    teamBPlayingAllRounders = teamBAllRounders;
    teamBPlayingBowlers = teamBBowlers;
  }

  const numberOfWicketKeepersOption = [];
  const totalNumberOfWicketKeepersPlaying = teamAPlayingWicketKeepers.concat(teamBPlayingWicketKeepers);
  for (let i = 0; i < totalNumberOfWicketKeepersPlaying.length; i += 1) {
    numberOfWicketKeepersOption.push(i + 1);
  }

  const q1Charts = [];
  const WicketKeepers = teamAPlayingWicketKeepers.concat(teamBPlayingWicketKeepers);
  q1Charts.push(getTopFiveRunScorersChart(WicketKeepers, WicketKeepers.length));

  const q2Charts = [];
  const batters = teamAPlayingBatters.concat(teamBPlayingBatters);
  q2Charts.push(getTopFiveRunScorersChart(batters, batters.length));

  const q3Charts = [];
  const allRounders = teamAPlayingAllRounders.concat(teamBPlayingAllRounders);
  q3Charts.push(getTopFiveRunScorersChart(allRounders, allRounders.length));
  q3Charts.push(getTopFiveWicketTakersChart(allRounders, allRounders.length));

  const q4Charts = [];
  const bowlers = teamAPlayingBowlers.concat(teamBPlayingBowlers);
  q4Charts.push(getTopFiveWicketTakersChart(bowlers, bowlers.length));

  const initialQuestions = [
    {
      question: 'How many Wicket Keepers you want in your fantasy Team?',
      options: numberOfWicketKeepersOption,
      answered: false,
      stats: 'Player Stats - WicketKeepers',
      statsDuration: '',
      statsCharts: q1Charts,
    },
    {
      question: 'How many Batters you want in your fantasy Team?',
      options: [],
      answered: false,
      stats: 'Player Stats - Batters',
      statsDuration: '',
      statsCharts: q2Charts,
    },
    {
      question: 'How many All Rounders you want in your fantasy Team?',
      options: [],
      answered: false,
      stats: 'Player Stats - AllRounders',
      statsDuration: '',
      statsCharts: q3Charts,
    },
    {
      question: 'How many Bowlers you want in your fantasy Team?',
      options: [],
      answered: false,
      stats: 'Player Stats - Bowlers',
      statsDuration: '',
      statsCharts: q4Charts,
    },
    {
      question: 'How many fantasy teams you want to generate?',
      options: ['1', '2'],
      answered: false,
      stats: 'Your Guts Matters!',
      statsDuration: '',
      statsCharts: [],
    },
  ];

  const [answers, setAnswers] = useState(Array(5).fill(''));
  const [isCompleted, setIsCompleted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState(initialQuestions);

  useEffect(() => {
    let updated = false;
    const newQuestions = questions.map((q, idx) => {
      if (answers[idx] !== '') {
        if (!q.answered) {
          updated = true;
          return { ...q, answered: true };
        }
      } else {
        if (q.answered && idx > 0 && answers[idx - 1] === '') {
          updated = true;
          return { ...q, options: [], answered: false };
        }
      }
      return q;
    });

    if (updated) {
      setQuestions(newQuestions);
    }
  }, [answers]);

  const generateOptionsBasedOnFirstAnswer = (answer) => {
    const numberOfOptions = parseInt(answer, 10);
    let maxNumberOfBattersUserCanSelect = 11 - numberOfOptions - 2;
    const playingBatters = teamAPlayingBatters.concat(teamBPlayingBatters);
    if (maxNumberOfBattersUserCanSelect > playingBatters.length) {
      maxNumberOfBattersUserCanSelect = playingBatters.length;
    }

    const optionList = [];
    for (let i = 0; i < maxNumberOfBattersUserCanSelect; i += 1) {
      optionList.push(i + 1);
    }

    return optionList;
  };

  const generateOptionsBasedOnFirstAndSecondAnswer = (answerFirst, answerSecond) => {
    const firstOption = parseInt(answerFirst, 10);
    const seoncdOption = parseInt(answerSecond, 10);
    let maxNumberOfAllRoundersUserCanSelect = 11 - (firstOption + seoncdOption) - 1;
    const playingAllRounders = teamAPlayingAllRounders.concat(teamBPlayingAllRounders);
    if (maxNumberOfAllRoundersUserCanSelect > playingAllRounders.length) {
      maxNumberOfAllRoundersUserCanSelect = playingAllRounders.length;
    }

    const optionList = [];
    for (let i = 0; i < maxNumberOfAllRoundersUserCanSelect; i += 1) {
      optionList.push(i + 1);
    }

    return optionList;
  };

  const generateOptionsBasedOnFirstAndSecondAndThirdAnswer = (answerFirst, answerSecond, answerThird) => {
    const firstOption = parseInt(answerFirst, 10);
    const seoncdOption = parseInt(answerSecond, 10);
    const thirdOption = parseInt(answerThird, 10);
    let maxNumberOfBowlersUserCanSelect = 11 - (firstOption + seoncdOption + thirdOption);
    const playingBowlers = teamAPlayingBowlers.concat(teamBPlayingBowlers);
    if (maxNumberOfBowlersUserCanSelect > playingBowlers.length) {
      maxNumberOfBowlersUserCanSelect = playingBowlers.length;
    }

    const optionList = [];
    optionList.push(maxNumberOfBowlersUserCanSelect);
    return optionList;
  };

  const updateQuestionOptions = (questionIndex, newOptions) => {
    setQuestions((prevQuestions) => prevQuestions.map((question, idx) => (idx === questionIndex ? { ...question, options: newOptions } : question)));
  };

  useEffect(() => {
    if (answers[0] !== '') {
      const newOptionsForSecondQuestion = generateOptionsBasedOnFirstAnswer(answers[0]);
      updateQuestionOptions(1, newOptionsForSecondQuestion);
    }
  }, [answers[0]]);

  useEffect(() => {
    if (answers[0] !== '' && answers[1] !== '') {
      const newOptionsForThirdQuestion = generateOptionsBasedOnFirstAndSecondAnswer(answers[0], answers[1]);
      updateQuestionOptions(2, newOptionsForThirdQuestion);
    }
  }, [answers[0], answers[1]]);

  useEffect(() => {
    if (answers[0] !== '' && answers[1] !== '' && answers[2] !== '') {
      const newOptionsForFourthQuestion = generateOptionsBasedOnFirstAndSecondAndThirdAnswer(answers[0], answers[1], answers[2]);
      updateQuestionOptions(3, newOptionsForFourthQuestion);
    }
  }, [answers[0], answers[1], answers[2]]);

  const handleAnswer = (selectedAnswer, questionIndex) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = selectedAnswer;
    setAnswers(newAnswers);

    setQuestions((prevQuestions) => prevQuestions.map((q, idx) => (idx === questionIndex ? { ...q, answered: true } : q)));

    const allQuestionsAnswered = newAnswers.every((answer) => answer !== '');
    setIsCompleted(allQuestionsAnswered);
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1 && questions[currentQuestionIndex].answered) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const restartQuestionnaire = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(5).fill(''));
    setIsCompleted(false);
    setQuestions([...initialQuestions]);

    const fantasyQuestionnaireElement = document.querySelector('.statsNguts__fantasyQuestionnaireByCombinations');
    if (fantasyQuestionnaireElement) {
      fantasyQuestionnaireElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div ref={ref}>
      <div className="statsNguts__fantasyQuestionnaireByCombinations">
        <div className="statsNguts__fantasyQuestionnaireByCombinations_heading">
          <h1 className="gradient__text">Generate Fantasy Teams</h1>
          <h3>Select number of Wicket Keepers, Batters, All Rounders and Bowlers
          </h3>
        </div>
        <div>
          {isCompleted ? (
            <div>
              <FantasyTeamsByCombinations
                questions={questions}
                answers={answers}
                playerStats={playerStats}
                restartQuestionnaire={restartQuestionnaire}
                matchInfo={matchInfo}
              />
            </div>
          ) : (
            <FantasyQuestionByCombinations
              question={questions[currentQuestionIndex]}
              answer={answers[currentQuestionIndex]}
              onAnswer={(selectedAnswer) => handleAnswer(selectedAnswer, currentQuestionIndex)}
              onNext={handleNext}
              onPrevious={handlePrevious}
              questionIndex={currentQuestionIndex}
              totalQuestions={questions.length}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default FantasyQuestionnaireByCombinations;
