import React from 'react';

const MostWicketsStats = ({ items }) => {
  const topThreeWicketTakers = items.slice(0, 2);

  return (
    <div>
      <div className="statsNguts__matchAnalysis_categoryTitle">Most Wickets</div>
      {topThreeWicketTakers.map((item, index) => (
        <div className="statsNguts__matchAnalysis_categoryItem" key={index}><strong>{item.Name}</strong> - {item.Wickets} for {item.Runs} (Balls {item.Balls})</div>
      ))}
    </div>
  );
};

export default MostWicketsStats;
