// MatchNews.js
import React from 'react';
import News from '../news/News';

import './matchNews.css';

const MatchNews = React.forwardRef(({ matchNews }, ref) => {
  const newsItem = matchNews.articles;
  newsItem.sort((a, b) => {
    const dateA = new Date(a.date.split('-').reverse().join('-'));
    const dateB = new Date(b.date.split('-').reverse().join('-'));
    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  });

  return (
    <div ref={ref}>
      <div className="statsNguts__matchNews" id="matchNews">
        <div className="statsNguts__matchNews_heading">
          <h1 className="gradient__text">Match News</h1>
          <h3>Recent Match Related News
          </h3>
        </div>
        <div className="statsNguts__matchNews_newsList">
          {newsItem.map((news, index) => (
            <div key={index} className="statsNguts__matchNews_newsList_itemContainer">
              <News
                platform={news.platform}
                avatar={news.ownerAvatar}
                text={news.text}
                platformLink={news.platformLink}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default MatchNews;
