export const getTopRunScorers = (players, count) => {
  const allPlayerRunScored = [];
  const totalPlayers = players.length;

  for (let i = 0; i < totalPlayers; i += 1) {
    const { runsScored, name } = players[i];
    let totalRunsScored = 0;
    if (runsScored.length > 0) {
      for (let j = 0; j < runsScored.length; j += 1) {
        totalRunsScored += runsScored[j].Runs;
      }

      const playerRunsScored = {
        Name: name,
        Runs: totalRunsScored,
      };

      allPlayerRunScored.push(playerRunsScored);
    }
  }

  allPlayerRunScored.sort((a, b) => b.Runs - a.Runs);
  const topRunScorers = allPlayerRunScored.slice(0, count);
  return topRunScorers;
};

export const getTopWicketTakers = (players, bowlerType, count) => {
  const allPlayerWicketTakens = [];
  let eligiblePlayers = [];
  if (bowlerType === 'Slow') {
    eligiblePlayers = players.filter((player) => !player.bowlingStyle.includes('Fast'));
  } else if (bowlerType === 'Fast') {
    eligiblePlayers = players.filter((player) => player.bowlingStyle.includes('Fast'));
  } else {
    eligiblePlayers = players;
  }

  for (let i = 0; i < eligiblePlayers.length; i += 1) {
    const { wicketsTaken, name } = eligiblePlayers[i];
    let totalWicketsTaken = 0;
    let totalBallsBowled = 0;
    if (wicketsTaken.length > 0) {
      for (let j = 0; j < wicketsTaken.length; j += 1) {
        totalWicketsTaken += wicketsTaken[j].Wickets;
        totalBallsBowled += wicketsTaken[j].Balls;
      }

      const playerWicketsTaken = {
        Name: name,
        Wickets: totalWicketsTaken,
        Balls: totalBallsBowled,
      };

      allPlayerWicketTakens.push(playerWicketsTaken);
    }
  }

  allPlayerWicketTakens.sort((a, b) => b.Wickets - a.Wickets);
  const topWicketTakers = allPlayerWicketTakens.slice(0, count);
  return topWicketTakers;
};
