import React, { useState } from 'react';
import FantasyQuestion from '../fantasyQuestion/FantasyQuestion';
import FantasyTeams from '../fantasyTeams/FantasyTeams';
import './fantasyQuestionnaire.css';
import {
  getH2HWinsAndLossesChart,
  getAverageInningScoresChart,
  getWicketTakenByFastAndSlowBowlerChart,
  getTopFiveRunScorersChart,
  getTopFiveWicketTakersChart,
  getHeadToHeadRunsComparisonChart,
  getHeadToHeadWicketsComparisonChart,
  getTeamWinsAndLossesOnGroundChart,
  getAverageInningWicketsChart,
  getEconomyRateAndStrikeRateOfFastAndSlowBowlersOnGroundChart,
  getH2HPlayerMostRunsChart,
  getH2HPlayerMostWicketsChart,
  getTeamPlayerMostRunsChart,
  getTeamPlayerMostWicketsChart,
  getBestStrikeRate,
  getBestEconomyRate,
} from '../utils/ChartsUtil.jsx';

const FantasyQuestionnaire = React.forwardRef(({ matchInfo, playerStats, groundStats, teamStats }, ref) => {
  let teamAfantasyPlayerList = [];
  let teamAPlayingWicketKeepers = [];
  let teamAPlayingBatters = [];
  let teamAPlayingAllRounders = [];
  let teamAPlayingBowlers = [];
  let teamBfantasyPlayerList = [];
  let teamBPlayingWicketKeepers = [];
  let teamBPlayingBatters = [];
  let teamBPlayingAllRounders = [];
  let teamBPlayingBowlers = [];

  teamAfantasyPlayerList = matchInfo.teamAPlayingSquad;
  teamBfantasyPlayerList = matchInfo.teamBPlayingSquad;

  const teamAWicketkeepers = playerStats.teamA_WicketKeepers.filter((player) => player.status === 'Available');
  const teamABatters = playerStats.teamA_Batters.filter((player) => player.status === 'Available');
  const teamAAllRounders = playerStats.teamA_AllRounders.filter((player) => player.status === 'Available');
  const teamABowlers = playerStats.teamA_Bowlers.filter((player) => player.status === 'Available');
  const teamBWicketkeepers = playerStats.teamB_WicketKeepers.filter((player) => player.status === 'Available');
  const teamBBatters = playerStats.teamB_Batters.filter((player) => player.status === 'Available');
  const teamBAllRounders = playerStats.teamB_AllRounders.filter((player) => player.status === 'Available');
  const teamBBowlers = playerStats.teamB_Bowlers.filter((player) => player.status === 'Available');

  if (teamAfantasyPlayerList.length > 0) {
    teamAWicketkeepers.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingWicketKeepers.push(player);
      }
    });

    teamABatters.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingBatters.push(player);
      }
    });

    teamAAllRounders.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingAllRounders.push(player);
      }
    });

    teamABowlers.forEach((player) => {
      const isPlayerPlaying = teamAfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamAPlayingBowlers.push(player);
      }
    });
  } else {
    teamAPlayingWicketKeepers = teamAWicketkeepers;
    teamAPlayingBatters = teamABatters;
    teamAPlayingAllRounders = teamAAllRounders;
    teamAPlayingBowlers = teamABowlers;
  }

  if (teamBfantasyPlayerList.length > 0) {
    teamBWicketkeepers.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingWicketKeepers.push(player);
      }
    });

    teamBBatters.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingBatters.push(player);
      }
    });

    teamBAllRounders.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingAllRounders.push(player);
      }
    });

    teamBBowlers.forEach((player) => {
      const isPlayerPlaying = teamBfantasyPlayerList.find((p) => p.Id === player.id);
      if (isPlayerPlaying) {
        teamBPlayingBowlers.push(player);
      }
    });
  } else {
    teamBPlayingWicketKeepers = teamBWicketkeepers;
    teamBPlayingBatters = teamBBatters;
    teamBPlayingAllRounders = teamBAllRounders;
    teamBPlayingBowlers = teamBBowlers;
  }

  const teamOptionList = [`${matchInfo.teamAShortName}`, `${matchInfo.teamBShortName}`];
  const yesAndNoOption = ['Yes', 'No'];
  const teamAWicketkeeperNames = teamAPlayingWicketKeepers.map((wicketkeeper) => wicketkeeper.name);
  const teamABatterNames = teamAPlayingBatters.map((batter) => batter.name);
  const teamAAllRounderNames = teamAPlayingAllRounders.map((allRounder) => allRounder.name);
  const teamABowlerNames = teamAPlayingBowlers.map((bowler) => bowler.name);
  const teamBWicketkeeperNames = teamBPlayingWicketKeepers.map((wicketkeeper) => wicketkeeper.name);
  const teamBBatterNames = teamBPlayingBatters.map((batter) => batter.name);
  const teamBAllRounderNames = teamBPlayingAllRounders.map((allRounder) => allRounder.name);
  const teamBBowlerNames = teamBPlayingBowlers.map((bowler) => bowler.name);

  const q1Charts = [];
  if (teamStats.h2hMatches.length > 0) {
    q1Charts.push(getH2HWinsAndLossesChart(matchInfo.teamAShortName, matchInfo.teamBShortName, teamStats.h2hMatches));
  }
  q1Charts.push(getTeamWinsAndLossesOnGroundChart(`${matchInfo.matchGroundFullName}, ${matchInfo.matchGroundCity}`, matchInfo.teamAShortName, teamStats.teamARecentPerformances, matchInfo.teamBShortName, teamStats.teamBRecentPerformances));

  const q2Charts = [];
  q2Charts.push(getAverageInningScoresChart(groundStats.firstInningsScore, groundStats.secondInningsScore));
  q2Charts.push(getAverageInningWicketsChart(groundStats.firstInningsScore, groundStats.secondInningsScore));

  const q3Charts = [];
  if (teamStats.h2hMatches.length > 0) {
    q3Charts.push(getHeadToHeadRunsComparisonChart(teamStats.h2hMatches, matchInfo.teamAShortName, matchInfo.teamBShortName));
    q3Charts.push(getHeadToHeadWicketsComparisonChart(teamStats.h2hMatches, matchInfo.teamAShortName, matchInfo.teamBShortName));
  }

  const q4Charts = [];
  q4Charts.push(getWicketTakenByFastAndSlowBowlerChart(groundStats.wicketsTakenByFastBowlers, groundStats.wicketsTakenBySlowBowlers));
  q4Charts.push(getEconomyRateAndStrikeRateOfFastAndSlowBowlersOnGroundChart(groundStats.wicketsTakenByFastBowlers, groundStats.wicketsTakenBySlowBowlers));

  const q5Charts = [];
  q5Charts.push(getTopFiveRunScorersChart(teamAPlayingWicketKeepers.concat(teamBPlayingWicketKeepers)));
  q5Charts.push(getH2HPlayerMostRunsChart(teamAPlayingWicketKeepers.concat(teamBPlayingWicketKeepers), teamStats.h2hBattingPerformances));
  q5Charts.push(getBestStrikeRate(teamAPlayingWicketKeepers.concat(teamBPlayingWicketKeepers)));

  const q6Charts = [];
  q6Charts.push(getTopFiveRunScorersChart(teamAPlayingBatters.concat(teamBPlayingBatters)));
  q6Charts.push(getH2HPlayerMostRunsChart(teamAPlayingBatters.concat(teamBPlayingBatters), teamStats.h2hBattingPerformances));
  q6Charts.push(getBestStrikeRate(teamAPlayingBatters.concat(teamBPlayingBatters)));

  const q7Charts = [];
  q7Charts.push(getTopFiveRunScorersChart(teamAPlayingAllRounders.concat(teamBPlayingAllRounders)));
  q7Charts.push(getH2HPlayerMostRunsChart(teamAPlayingAllRounders.concat(teamBPlayingAllRounders), teamStats.h2hBattingPerformances));
  q7Charts.push(getBestStrikeRate(teamAPlayingAllRounders.concat(teamBPlayingAllRounders)));
  q7Charts.push(getTopFiveWicketTakersChart(teamAPlayingAllRounders.concat(teamBPlayingAllRounders)));
  q7Charts.push(getH2HPlayerMostWicketsChart(teamAPlayingAllRounders.concat(teamBPlayingAllRounders), teamStats.h2hBowlingPerformances));
  q7Charts.push(getBestEconomyRate(teamAPlayingAllRounders.concat(teamBPlayingAllRounders)));

  const q8Charts = [];
  q8Charts.push(getTopFiveWicketTakersChart(teamAPlayingBowlers.concat(teamBPlayingBowlers)));
  q8Charts.push(getH2HPlayerMostWicketsChart(teamAPlayingBowlers.concat(teamBPlayingBowlers), teamStats.h2hBowlingPerformances));
  q8Charts.push(getBestEconomyRate(teamAPlayingBowlers.concat(teamBPlayingBowlers)));

  const q9Charts = [];
  q9Charts.push(getTopFiveRunScorersChart(teamAPlayingWicketKeepers.concat(teamAPlayingBatters).concat(teamAPlayingAllRounders)));
  q9Charts.push(getTeamPlayerMostRunsChart(matchInfo.teamAShortName, teamAPlayingWicketKeepers.concat(teamAPlayingBatters).concat(teamAPlayingAllRounders), teamStats.teamARecentPerformances));
  q9Charts.push(getBestStrikeRate(teamAPlayingWicketKeepers.concat(teamAPlayingBatters).concat(teamAPlayingAllRounders)));

  const q10Charts = [];
  q10Charts.push(getTopFiveRunScorersChart(teamBPlayingWicketKeepers.concat(teamBPlayingBatters).concat(teamBPlayingAllRounders)));
  q10Charts.push(getTeamPlayerMostRunsChart(matchInfo.teamBShortName, teamBPlayingWicketKeepers.concat(teamBPlayingBatters).concat(teamBPlayingAllRounders), teamStats.teamBRecentPerformances));
  q10Charts.push(getBestStrikeRate(teamBPlayingWicketKeepers.concat(teamBPlayingBatters).concat(teamBPlayingAllRounders)));

  const q11Charts = [];
  q11Charts.push(getTopFiveWicketTakersChart(teamAPlayingAllRounders.concat(teamAPlayingBowlers)));
  q11Charts.push(getTeamPlayerMostWicketsChart(matchInfo.teamAShortName, teamAPlayingAllRounders.concat(teamAPlayingBowlers), teamStats.teamARecentPerformances));
  q11Charts.push(getBestEconomyRate(teamAPlayingAllRounders.concat(teamAPlayingBowlers)));

  const q12Charts = [];
  q12Charts.push(getTopFiveWicketTakersChart(teamBPlayingAllRounders.concat(teamBPlayingBowlers)));
  q12Charts.push(getTeamPlayerMostWicketsChart(matchInfo.teamBShortName, teamBPlayingAllRounders.concat(teamBPlayingBowlers), teamStats.teamBRecentPerformances));
  q12Charts.push(getBestEconomyRate(teamBPlayingAllRounders.concat(teamBPlayingBowlers)));

  const questions = [
    { question: 'What\'s your gut feeling about which team is poised to emerge victorious in the match?', options: teamOptionList, stats: 'Team Stats', statsDuration: '', statsCharts: q1Charts },
    { question: 'Do you have a gut feeling whether the match will result in a high-scoring affair?', options: yesAndNoOption, stats: 'Ground Stats', statsDuration: 'Matches on Ground since January 1st, 2021', statsCharts: q2Charts },
    { question: 'In your gut feeling, do you think one team will clearly outmatch the other in the match?', options: yesAndNoOption, stats: 'Team Stats', statsDuration: 'Last 10 Head-To-Head Matches', statsCharts: q3Charts },
    { question: 'Based on your gut feeling, do you think spinners will be more effective on this pitch compared to fast bowlers?', options: yesAndNoOption, stats: 'Ground Stats', statsDuration: 'Matches on Ground since January 1st, 2021', statsCharts: q4Charts },
    { question: 'Based on your instincts, which Wicket Keeper must be included in your team?', options: teamAWicketkeeperNames.concat(teamBWicketkeeperNames), stats: 'Player Stats - Wicket Keepers', statsDuration: '', statsCharts: q5Charts },
    { question: 'Based on your instincts, which Batter must be included in your team?', options: teamABatterNames.concat(teamBBatterNames), stats: 'Player Stats - Batters', statsDuration: '', statsCharts: q6Charts },
    { question: 'Based on your instincts, which All Rounder must be included in your team?', options: teamAAllRounderNames.concat(teamBAllRounderNames), stats: 'Player Stats - All Rounders', statsDuration: '', statsCharts: q7Charts },
    { question: 'Based on your instincts, which Bowler must be included in your team?', options: teamABowlerNames.concat(teamBBowlerNames), stats: 'Player Stats - Bowlers', statsDuration: '', statsCharts: q8Charts },
    { question: `Going with your gut, which Batter from ${matchInfo.teamAShortName} do you think will score the most runs in the match?`, options: teamAWicketkeeperNames.concat(teamABatterNames).concat(teamAAllRounderNames), stats: 'Player Stats - Wicket Keepers, Batters, All Rounders', statsDuration: '', statsCharts: q9Charts },
    { question: `Going with your gut, which Batter from ${matchInfo.teamBShortName} do you think will score the most runs in the match?`, options: teamBWicketkeeperNames.concat(teamBBatterNames).concat(teamBAllRounderNames), stats: 'Player Stats - Wicket Keepers, Batters, All Rounders', statsDuration: '', statsCharts: q10Charts },
    { question: `Going with your gut, which Bowler from ${matchInfo.teamAShortName} do you think will take the most wickets in the match?`, options: teamAAllRounderNames.concat(teamABowlerNames), stats: 'Player Stats - Bowlers, All Rounders', statsDuration: '', statsCharts: q11Charts },
    { question: `Going with your gut, which Bowler from ${matchInfo.teamBShortName} do you think will take the most wickets in the match?`, options: teamBAllRounderNames.concat(teamBBowlerNames), stats: 'Player Stats - Bowlers, All Rounders', statsDuration: '', statsCharts: q12Charts },
    { question: 'How many fantasy team you want to generate?', options: ['1', '2', '3', '4'], stats: 'Please ensure that all questions have been answered!', statsDuration: '', statsCharts: [] },
  ];

  const [answers, setAnswers] = useState(Array(13).fill(''));
  const [isCompleted, setIsCompleted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleAnswer = (selectedAnswer, questionIndex) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = selectedAnswer;
    setAnswers(newAnswers);
    const allQuestionsAnswered = newAnswers.every((answer) => answer !== '');
    setIsCompleted(allQuestionsAnswered);
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const restartQuestionnaire = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(13).fill(''));
    setIsCompleted(false);

    const fantasyQuestionnaireElement = document.querySelector('.statsNguts__fantasyQuestionnaire');
    if (fantasyQuestionnaireElement) {
      fantasyQuestionnaireElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div ref={ref}>
      <div className="statsNguts__fantasyQuestionnaire">
        <div className="statsNguts__fantasyQuestionnaire_heading">
          <h1 className="gradient__text">Generate Fantasy Teams</h1>
          <h3>Start by answering simple questions about the match!</h3>
        </div>
        <div>
          {isCompleted ? (
            <div>
              <FantasyTeams
                questions={questions}
                answers={answers}
                playerStats={playerStats}
                restartQuestionnaire={restartQuestionnaire}
                matchInfo={matchInfo}
              />
            </div>
          ) : (
            <FantasyQuestion
              question={questions[currentQuestionIndex]}
              answer={answers[currentQuestionIndex]}
              onAnswer={(selectedAnswer) => handleAnswer(selectedAnswer, currentQuestionIndex)}
              onNext={handleNext}
              onPrevious={handlePrevious}
              questionIndex={currentQuestionIndex}
              totalQuestions={questions.length}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default FantasyQuestionnaire;
