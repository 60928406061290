import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { signOut } from '@aws-amplify/auth';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../../assets/image/logo.png';
import './navbar.css';

const Navbar = ({ isMenuVisible }) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);

  const handleSignInClick = () => {
    ReactGA.event({
      category: 'User Actions',
      action: 'SignIn-Click',
      label: 'UserSignIn',
    });
    navigate('/login');
    setToggleMenu(false);
  };

  const handleSignOutClick = async () => {
    try {
      await signOut({ global: true });
      setToggleMenu(false);
      navigate('/'); // Redirect to home after sign out
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error signing out:', error);
    }
  };

  const renderLinks = () => (
    <>
      <p><a href="#home">Home</a></p>
      {user && <h4 className="statsNguts__navbar_menu_container_links_welcomeMessage">Welcome, {user.signInDetails.loginId.toLowerCase()}!</h4>}
    </>
  );

  return (
    <div className="statsNguts__navbar">
      <div className="statsNguts__navbar_links">
        <div className="statsNguts__navbar_links_logo">
          <a href="https://www.statsnguts.com">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        {isMenuVisible && (
          <div className="statsNguts__navbar_links_container">
            {renderLinks()}
          </div>
        )}
        {!isMenuVisible && (
          <div className="statsNguts__navbar_centered_text">
            <p className="statsNguts__navbar_big_text">Uncover the Stats That Makes a Difference!</p>
          </div>
        )}
      </div>

      <div className="statsNguts__navbar_sign">
        {user ? (
          <button type="button" onClick={handleSignOutClick}>Sign out</button>
        ) : (
          <button type="button" onClick={handleSignInClick}>Sign in</button>
        )}
      </div>

      <div className="statsNguts__navbar_menu">
        {isMenuVisible && (
          toggleMenu
            ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        )}
        {toggleMenu && (
          <div className="statsNguts__navbar_menu_container scale_up_center">
            <div className="statsNguts__navbar_menu_container_links">
              {renderLinks()}
            </div>
            <div className="statsNguts__navbar_menu_container_links_sign">
              {user ? (
                <button type="button" onClick={handleSignOutClick}>Sign out</button>
              ) : (
                <button type="button" onClick={handleSignInClick}>Sign in</button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
