/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import './fanatsyTeamPrediction.css';
import { GetFantasyTeams } from './fanatsyTeamPredictionUtil.jsx';

const FanatsyTeamPrediction = React.forwardRef(({ playerStats, matchInfo }, ref) => {
  const fantasyTeams = GetFantasyTeams(playerStats, matchInfo);
  return (
    <div ref={ref}>
      <div className="statsNguts__fanatsyTeamPrediction">
        <h2>Fantasy Team Prediction<br />
          <h5 className="statsNguts__fanatsyTeamPrediction_Disclaimer">
            *Suggested Team is based on tentative playing XI of both the teams. Actual playing XI will be decided after toss on Match day
          </h5>
        </h2>
        <div className="statsNguts__fanatsyTeamPrediction_container">
          {fantasyTeams.map((team, teamIndex) => (
            <div key={teamIndex} className="statsNguts__fanatsyTeamPrediction_container_team">
              <h3 className="statsNguts__fanatsyTeamPrediction_container_team_name">Match {matchInfo.matchNumber} - {matchInfo.teamAShortName} Vs {matchInfo.teamBShortName} - {team.teamName} </h3>
              {['WicketKeeper', 'Batter', 'AllRounder', 'Bowler'].map((role) => (
                <div key={role} className="statsNguts__fanatsyTeamPrediction_container_team_section">
                  <h3>{role}s</h3>
                  <div className="statsNguts__fanatsyTeamPrediction_container_players">
                    {team.players.filter((player) => player.role === role).map((player, index) => (
                      <div key={index} className="statsNguts__fanatsyTeamPrediction_container_player_item">
                        <img src={require(`../../../../assets/image/players/${player.avatar}`)} alt={player.name} className="statsNguts__fanatsyTeamPrediction_container_player_avatar" />
                        <div className="statsNguts__fanatsyTeamPrediction_container_player_info">
                          <p className="statsNguts__fanatsyTeamPrediction_container_player_name">{player.name}</p>
                          {player.isCaptain && <span className="statsNguts__fanatsyTeamPrediction_container_player_role_captain">(C)</span>}
                          {player.isViceCaptain && <span className="statsNguts__fanatsyTeamPrediction_container_player_role_vice-captain">(VC)</span>}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default FanatsyTeamPrediction;
