import React from 'react';
import './about.css';

const About = () => (
  <div className="statsNguts__about" id="about">
    <div className="statsNguts__about_heading">
      <h1 className="gradient__text">About Us</h1>
      <h3>We are passionate about sports and realise the importance of statistics and data in the game. Our moto is to serve the sports community with top statistics which helps them to enjoy the data of the game and take an informed decision while creating a fantasy team.
        <br /><br />
        We aim to design a robust and an intelligent tool which helps you to generate multiple fantasy teams with different combination backed by our data of the game. We have designed a data driven AI tool which leverages the findings from the statistcs to turn them into actionable results allowing them to have maximum potential for users to take an informed decision.
      </h3>
    </div>
  </div>
);

export default About;
