import { React, StrictMode } from 'react';
import { ThemeProvider, Authenticator } from '@aws-amplify/ui-react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import App from './App';
import './index.css';

const TRACKING_ID = 'G-CVR0PS5D4C';
ReactGA.initialize(TRACKING_ID);

const root = createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <ThemeProvider>
      <Authenticator.Provider>
        <App />
      </Authenticator.Provider>
    </ThemeProvider>
  </StrictMode>,
  root,
);
